import User from "../Types/User";
import { fetchBase } from "./fetchBase";

export const userLogin = async (user: User) =>
  fetchBase<User>("auth/login", "POST", user);

export const userRegister = async (user: User) =>
  fetchBase<User>("auth/register", "POST", user);

export const confirmEmailAddress = async (
  confirmationId: string,
  email: string
) =>
  fetchBase<User>("auth/confirmEmail", "POST", {
    confirmationCode: confirmationId,
    email,
  });

export const userLogout = async () =>
  fetchBase<undefined>("auth/logout", "POST", {});

export const deleteAccount = async () =>
  fetchBase<undefined>("account/delete", "DELETE", {});

export const updateAccount = async (name: string, email: string) =>
  fetchBase<undefined>("account/update", "POST", { name, email });

export const contactUs = async (message: string) =>
  fetchBase<undefined>("account/contactUs", "POST", { message });

export const triggerPasswordReset = async (email: string) =>
  fetchBase<undefined>("auth/triggerReset", "POST", { email });

export const resetPassword = async (
  token: string,
  password: string,
  email: string
) =>
  fetchBase<undefined>("auth/resetPassword", "POST", {
    token,
    password,
    email,
  });
