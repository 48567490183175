import React from "react";
import Card from "../../../Components/Card/Card";
import { List, ListItem, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import NumberTile from "./NumberTile";

interface Props {
  title: string;
  lessonContent: string[];
}

const TitleCard = ({ title, lessonContent }: Props) => {
  const theme = useTheme();

  return (
    <Card title={title}>
      <Typography fontWeight="500">In this lesson, you will learn:</Typography>
      <List sx={{ pl: 2 }}>
        {lessonContent.map((content, index) => (
          <ListItem
            key={title + "-content-" + index}
            sx={{ color: theme.palette.text.secondary, pl: 0 }}
          >
            <NumberTile number={index + 1} />
            {content}
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default TitleCard;
