import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import TitleCard from "./Components/TitleCard";
import YouTubeCard from "./Components/YouTubeCard";
import QuestionContainer from "./Components/QuestionContainer";
import { Lesson as LessonType } from "../../Api/Types/Lesson";
import { getLesson } from "../../Api/Endpoints/lessonsCalls";
import { SetSnackbar } from "../../Context/SnackbarContext";
import { Box, CircularProgress } from "@mui/material";

export default function Lesson() {
  const [lesson, setLesson] = useState<LessonType>();
  const [lessonId, setLessonId] = useState("");
  const [courseId, setCourseId] = useState("");
  const [loading, setLoading] = useState(true);
  const setSnackbar = useContext(SetSnackbar);

  useEffect(() => {
    const pathNames = window.location.pathname?.split("/");

    // Invaild path
    if (!pathNames || pathNames.length < 4) {
      setLoading(false);
      return;
    }

    const courseId = pathNames[2];
    const lessonId = pathNames[3];
    setCourseId(courseId);
    setLessonId(lessonId);

    setLoading(true);
    getLesson(lessonId, courseId)
      .then((x) => {
        if (x.data) {
          // Mix up the order of each of the question answers
          if (x.data.questions) {
            x.data.questions = x.data.questions.map((question) => {
              if (question.choices && question.preserveChoiceOrder !== true) {
                question.choices = question.choices.sort(
                  () => Math.random() - 0.5
                );
              }

              return question;
            });
          }
          setLesson(x.data);
        } else {
          setSnackbar({ message: x.message, status: "error" });
        }

        setLoading(false);
      })
      .catch(() => {
        setSnackbar({
          message: "Could not get lesson. Please try again later",
          status: "error",
        });

        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else if (lesson === undefined) {
    window.location.href = "/home";
    return <></>;
  }

  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      paddingX={{ xs: 0, x: 0, s: 4, m: 7, l: 8, xl: 10 }}
    >
      <TitleCard title={lesson.title} lessonContent={lesson.lessonContent} />
      <YouTubeCard embedId={lesson.youtubeEmbedId} />
      <QuestionContainer
        questions={lesson.questions}
        courseId={courseId}
        lessonId={lessonId}
      />
    </Grid>
  );
}
