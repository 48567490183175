import React from "react";
import { Grid, Box, Typography, Button, Theme } from "@mui/material";
import { isMobile } from "react-device-detect";
import {
  QuestionAnswerState,
  UserLessonProgress,
} from "../../../Api/Types/UserProgress";
import Card from "../../../Components/Card/Card";
import { Course as CourseType } from "../../../Api/Types/Course";
import { GetLessonUrl, GetPastPaperUrl } from "../../Router";

interface Props {
  course: CourseType;
  courseId: string | undefined;
  theme: Theme;
  courseAnswers: UserLessonProgress | undefined;
}

const calculateNextCourse = (
  course: CourseType,
  courseAnswers: UserLessonProgress | undefined,
  courseId: string | undefined
): string | null => {
  let returnedUrl: string | null = null;
  // Go to the first lesson that hasn't been completed
  for (const topic of course.topics) {
    for (const lesson of topic.lessons) {
      if (
        returnedUrl === null &&
        (courseAnswers === undefined || courseAnswers[lesson.url] === undefined)
      ) {
        returnedUrl = GetLessonUrl(lesson.url, courseId || "");
        break;
      }
    }
  }

  if (returnedUrl !== null) {
    return returnedUrl;
  }

  // If we're still here, go to the first lesson that we didn't get full marks on

  for (const topic of course.topics) {
    for (const lesson of topic.lessons) {
      if (
        returnedUrl === null &&
        (courseAnswers === undefined ||
          Object.values(
            JSON.parse(courseAnswers[lesson.url] as unknown as string)
          ).some((y) => y !== QuestionAnswerState.Correct))
      ) {
        returnedUrl = GetLessonUrl(lesson.url, courseId || "");
        break;
      }
    }
  }

  // Otherwise return null
  return returnedUrl;
};

const LinksCard = ({
  course,
  courseId,
  theme,
  courseAnswers,
}: Props): JSX.Element => {
  const nextCourseUrl = calculateNextCourse(course, courseAnswers, courseId);

  return (
    <Grid item xs={12} lg={4} marginTop={isMobile ? 0 : 6}>
      <Card height="100%">
        <Box
          sx={{
            height: "100%",
            width: "100%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              mb: 1,
              textAlign: "center",
              color: theme.palette.primary.main,
            }}
            fontWeight="400"
          >
            Useful Links
          </Typography>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              gap: 1,
              justifyContent: "center",
            }}
          >
            <Button
              variant="outlined"
              sx={{ backgroundColor: theme.palette.background.paper }}
              href={course.specLink}
            >
              Course Specification
            </Button>
            <Button
              variant="outlined"
              sx={{ backgroundColor: theme.palette.background.paper }}
              href={GetPastPaperUrl(courseId || "")}
            >
              Past Papers
            </Button>
            <Button
              variant="contained"
              href={nextCourseUrl || ""}
              disabled={nextCourseUrl === null}
            >
              Go To Next Lesson
            </Button>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};

export default LinksCard;
