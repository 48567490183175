import React from "react";
import "./App.css";
import NavBar from "./Nav/NavBar";
import { Box, Container, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Copyright } from "./Nav/NavBarSubComponents";
import { getLightModeTheme } from "./Theme/LightModeTheme";
import { loginLocations } from "./Pages/Router";
import SnackbarContext from "./Context/SnackbarContext";
import UserStore from "./Context/UserContext";
import { Constants } from "./Constants";
import Router from "./Pages/Router";
import { isMobile } from "react-device-detect";

function App() {
  const lightModeTheme = getLightModeTheme();

  document.title = Constants.APP_NAME;

  const hideNavbar = loginLocations.some(
    (path) => path === window.location.pathname
  );

  return (
    <>
      <ThemeProvider theme={lightModeTheme}>
        <SnackbarContext>
          <UserStore>
            <Box sx={{ display: "flex" }}>
              <CssBaseline />
              {!hideNavbar && <NavBar />}
              <Box
                component="main"
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? theme.palette.grey[50]
                      : theme.palette.grey[900],
                  flexGrow: 1,
                  height: "100vh",
                  overflow: "auto",
                  mt: !hideNavbar && isMobile ? 5 : undefined,
                }}
              >
                {hideNavbar ? (
                  <Router />
                ) : (
                  <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                    <Router />
                    <Copyright sx={{ pt: 4 }} />
                  </Container>
                )}
              </Box>
            </Box>
          </UserStore>
        </SnackbarContext>
      </ThemeProvider>
    </>
  );
}

export default App;
