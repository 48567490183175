import React, { useContext } from "react";
import { Box } from "@mui/material";
import { UserContext } from "../../Context/UserContext";
import "./assets/css/bootstrap.min.css";
import "./assets/css/tiny-slider.css";
import "./assets/css/lineicons.css";
import "./assets/css/glightbox.min.css";
import "./style.css";
import { Constants } from "../../Constants";
import { IsUserLoggedIn } from "../../Utils/IsUserLoggedIn";

// Not an ideal react component, but this is an HTML page from the following link, quickly ported across: https://onepagelove.com/ayro-business

const LandingPage = (): JSX.Element => {
  const user = useContext(UserContext);
  const isLoggedIn = IsUserLoggedIn(user);

  return (
    <Box sx={{ width: "100vw" }}>
      <section className="navbar-area navbar-nine">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg">
                <a className="navbar-brand" href="/">
                  <img
                    src="/Logo-white.webp"
                    alt="Logo"
                    style={{ maxHeight: "50px" }}
                  />
                </a>

                <div
                  className="collapse navbar-collapse sub-menu-bar"
                  id="navbarNine"
                >
                  <ul className="navbar-nav me-auto">
                    <li className="nav-item">
                      <a className="page-scroll" href="#introduction">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#learn-with-us">
                        Learn With Us
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#pricing">
                        Pricing
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#about">
                        About Us
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="page-scroll"
                        href={isLoggedIn ? "/home" : "/signup"}
                      >
                        {isLoggedIn ? "Keep Learning" : "Sign Up"}
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="introduction" className="header-area header-eight">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="header-content">
                <h1 style={{}}>
                  Ace Your OCR Computer Science GCSE with {Constants.APP_NAME}
                </h1>
                <p>
                  Easy to watch videos with exam-style questions to help you
                  assess your learning after each unit.
                </p>
                <div className="button">
                  <a
                    href={isLoggedIn ? "/home" : "/signup"}
                    className="btn primary-btn"
                  >
                    {isLoggedIn ? "Keep Learning" : "Sign Up"}
                  </a>
                  {isLoggedIn ? (
                    <></>
                  ) : (
                    <a href="/login" className="btn">
                      Login
                    </a>
                  )}
                </div>
              </div>
            </div>
            {/*<div className="col-lg-6 col-md-12 col-12">
              <div className="header-image">
                <img src="assets/images/header/hero-image.jpg" alt="#" />
              </div>
                  </div>*/}
          </div>
        </div>
      </section>

      <section id="learn-with-us" className="services-area services-eight">
        <div className="section-title-five">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <h2 className="fw-bold">Why Learn With Us?</h2>
                  <p>
                    Engaging videos paired with exam-style quizzes to test your
                    knowledge help you to prepare for your OCR Computer Science
                    GCSE exam in no time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6" style={{ textAlign: "center" }}>
              <div className="single-services">
                <div
                  className="service-icon"
                  style={{ margin: "auto", marginBottom: "30px" }}
                >
                  <i className="lni lni-shortcode"></i>
                </div>
                <div className="service-content">
                  <h4>Taught By A Developer</h4>
                  <p>
                    Lessons are taught by a professional software engineer,
                    meaning that you&apos;ll get real-life industry experience
                    baked into your lessons.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6" style={{ textAlign: "center" }}>
              <div className="single-services">
                <div
                  className="service-icon"
                  style={{ margin: "auto", marginBottom: "30px" }}
                >
                  <i className="lni lni-dashboard"></i>
                </div>
                <div className="service-content">
                  <h4>Easily See Your Progress</h4>
                  <p>
                    {Constants.APP_NAME} automatically saves your test results
                    so that you can see which topics you&apos;re acing, and
                    which you need to revisit.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6" style={{ textAlign: "center" }}>
              <div className="single-services">
                <div
                  className="service-icon"
                  style={{ margin: "auto", marginBottom: "30px" }}
                >
                  <i className="lni lni-layers"></i>
                </div>
                <div className="service-content">
                  <h4>Understand Whatever Your Learning Style</h4>
                  <p>
                    With videos and interactive quizzes, {Constants.APP_NAME}{" "}
                    lets you learn in whatever way you find easiest.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6" style={{ textAlign: "center" }}>
              <div className="single-services">
                <div
                  className="service-icon"
                  style={{ margin: "auto", marginBottom: "30px" }}
                >
                  <i className="lni lni-reload"></i>
                </div>
                <div className="service-content">
                  <h4>Revisit Lessons</h4>
                  <p>
                    Didn&apos;t understand something? Don&apos;t worry! Unlike
                    live lessons, {Constants.APP_NAME} lets you rewatch and
                    rewind videos so that you can master every topic.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="pricing" className="pricing-area pricing-fourteen">
        <div className="section-title-five">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <h2 className="fw-bold">Pricing</h2>
                  <p>
                    We&apos;re proud to release {Constants.APP_NAME} for free so
                    that you can access a high quality computer science
                    education.
                  </p>
                  <p>
                    Simply sign up for an account using the link below and get
                    access to all of our top quality learning resources.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div
            className="row"
            style={{ width: "100%", justifyContent: "center" }}
          >
            {/*<div className="col-lg-4 col-md-6 col-12">
              <div className="pricing-style-fourteen">
                <div className="table-head">
                  <h6 className="title">Starter</h6>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    industry.
                  </p>
                  <div className="price">
                    <h2 className="amount">
                      <span className="currency">$</span>0
                      <span className="duration">/mo </span>
                    </h2>
                  </div>
                </div>

                <div className="light-rounded-buttons">
                  <a
                    href="javascript:void(0)"
                    className="btn primary-btn-outline"
                  >
                    Start free trial
                  </a>
                </div>

                <div className="table-content">
                  <ul className="table-list">
                    <li>
                      {" "}
                      <i className="lni lni-checkmark-circle"></i> Cras justo
                      odio.
                    </li>
                    <li>
                      {" "}
                      <i className="lni lni-checkmark-circle"></i> Dapibus ac
                      facilisis in.
                    </li>
                    <li>
                      {" "}
                      <i className="lni lni-checkmark-circle deactive"></i>{" "}
                      Morbi leo risus.
                    </li>
                    <li>
                      {" "}
                      <i className="lni lni-checkmark-circle deactive"></i>{" "}
                      Excepteur sint occaecat velit.
                    </li>
                  </ul>
                </div>
              </div>
            </div>*/}
            <div className="col-lg-4 col-md-6 col-12">
              <div className="pricing-style-fourteen middle">
                <div className="table-head">
                  <h6 className="title">Free</h6>
                  <p>Get access to our video and quiz content for free.</p>
                  <div className="price">
                    <h2 className="amount">
                      <span className="currency">£</span>0
                      {/*<span className="duration">/mo </span>*/}
                    </h2>
                  </div>
                </div>

                <div className="light-rounded-buttons">
                  <a href="/signup" className="btn primary-btn">
                    Sign Up For Free
                  </a>
                </div>

                <div className="table-content">
                  <ul className="table-list">
                    <li>
                      <i className="lni lni-checkmark-circle"></i>High-Quality
                      Video Lessons
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle"></i>Exam-Style
                      Quiz Questions
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle"></i>Identify
                      Topics That Need Revisiting
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle"></i>
                      Completely Free Of Charge
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*<div className="col-lg-4 col-md-6 col-12">
              <div className="pricing-style-fourteen">
                <div className="table-head">
                  <h6 className="title">Premium</h6>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    industry.
                  </p>
                  <div className="price">
                    <h2 className="amount">
                      <span className="currency">$</span>150
                      <span className="duration">/mo </span>
                    </h2>
                  </div>
                </div>

                <div className="light-rounded-buttons">
                  <a
                    href="javascript:void(0)"
                    className="btn primary-btn-outline"
                  >
                    Start free trial
                  </a>
                </div>

                <div className="table-content">
                  <ul className="table-list">
                    <li>
                      {" "}
                      <i className="lni lni-checkmark-circle"></i> Cras justo
                      odio.
                    </li>
                    <li>
                      {" "}
                      <i className="lni lni-checkmark-circle"></i> Dapibus ac
                      facilisis in.
                    </li>
                    <li>
                      {" "}
                      <i className="lni lni-checkmark-circle"></i> Morbi leo
                      risus.
                    </li>
                    <li>
                      {" "}
                      <i className="lni lni-checkmark-circle"></i> Excepteur
                      sint occaecat velit.
                    </li>
                  </ul>
                </div>
              </div>
          </div>*/}
          </div>
        </div>
      </section>

      <div id="about" className="brand-area section">
        <div className="section-title-five">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <h2 className="fw-bold">About {Constants.APP_NAME}</h2>
                  <p>
                    {Constants.APP_NAME} was founded in 2024 with the goal of
                    enhancing student access to a high-quality Computer Science
                    education.
                  </p>
                  <p>
                    We&apos;re on a mission to redefine what it means to study
                    Computer Science by offering clear and in-depth video
                    lessons filled with relevant industry insights.
                  </p>
                  <br />
                  <br />
                  <p>
                    If you&apos;re ready to get started with your Computer
                    Science education, click the button below to sign up for
                    free.
                  </p>
                </div>
                <div
                  className="light-rounded-buttons"
                  style={{ marginTop: "50px" }}
                >
                  <a href="/signup" className="btn primary-btn">
                    Sign Up For Free
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer-area footer-eleven">
        <div className="footer-top">
          <div className="container">
            <div className="inner-content">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="footer-widget f-about">
                    <div className="logo">
                      <a href="/">
                        <img
                          src="Logo-primary.webp"
                          className="img-fluid"
                          style={{ maxHeight: "50px" }}
                        />
                      </a>
                    </div>
                    <p>
                      <b>{Constants.APP_NAME}</b>
                      <br />
                      Enhancing access to a high-quality Computer Science
                      Education.
                    </p>
                    <p className="copyright-text">
                      <span>
                        ©{new Date().getFullYear()} {Constants.APP_NAME}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="footer-widget f-link">
                    <h5>Get Started</h5>
                    <ul>
                      <li>
                        <a href="/login">Login</a>
                      </li>
                      <li>
                        <a href="/signup">Signup</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="footer-widget f-link">
                    <h5>Legal</h5>
                    <ul>
                      <li>
                        <a href="/privacy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms">Terms And Conditions</a>
                      </li>
                      <li>
                        <a href="/cookies">Cookies Policy</a>
                      </li>
                      <li>
                        <a href="/disclaimer">Disclaimer</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Box>
  );
};

export default LandingPage;
