import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useContext, useState } from "react";
import PasswordChecklist from "react-password-checklist";
import { IsEmailValid, IsOrgNameValid } from "./SignUpHelper";
import Turnstile from "react-turnstile";
import { UserDispatchContext } from "../../Context/UserContext";
import { SetSnackbar } from "../../Context/SnackbarContext";
import { userRegister } from "../../Api/Endpoints/userCalls";

export default function SignUp() {
  const isLocalhost = window.location.host === "localhost:3000";

  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [userNameValid, setUserNameValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [capatchaVerified, setCapatchaVerified] = useState(isLocalhost);
  const setUserContext = useContext(UserDispatchContext);
  const setSnackbar = useContext(SetSnackbar);

  const [signupComplete, setSignupComplete] = useState(false);

  const [isUserInteracting, setIsUserInteracting] = useState(isLocalhost);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isUserInteracting || !capatchaVerified) {
      return;
    }

    // Check for validation errors
    const uNameValid = IsOrgNameValid(userName);
    const emailVal = IsEmailValid(email);

    if (!(uNameValid && emailVal && passwordValid)) {
      setUserNameValid(uNameValid);
      setEmailValid(emailVal);
      return;
    }

    // Sign up
    userRegister({
      email: email,
      password: password,
      name: userName,
      id: "",
      confirmationCodes: {},
    })
      .then((x) => {
        if (x.data) {
          setUserContext(x.data);
          setSignupComplete(true);
        } else {
          setSnackbar({
            message: x.message,
            status: "error",
          });
        }
      })
      .catch(() => {
        setSnackbar({
          message:
            "Could not create user. Please check your details and make sure that your email address isn't already signed up",
          status: "error",
        });
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <AccountCircleIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {signupComplete ? "Please Check Your Inbox" : "Sign Up"}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {signupComplete ? (
            <>
              <Typography sx={{ mt: 5, mb: 5, textAlign: "center" }}>
                Please check your email inbox for a link to confirm your account
                to get started!
              </Typography>

              <Button
                fullWidth
                variant="contained"
                style={{ color: "white" }}
                sx={{ mt: 3, mb: 2 }}
                onClick={() => (window.location.href = "/login")}
              >
                Go To Login
              </Button>
            </>
          ) : (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="userName"
                label="Your Name"
                name="userName"
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                error={!userNameValid}
                helperText={!userNameValid && "Please enter a valid name"}
                onBlur={() => setUserNameValid(IsOrgNameValid(userName))}
                onMouseOver={() => setIsUserInteracting(true)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!emailValid}
                helperText={!emailValid && "Please enter a valid email address"}
                onBlur={() => setEmailValid(IsEmailValid(email))}
                onMouseOver={() => setIsUserInteracting(true)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onMouseOver={() => setIsUserInteracting(true)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmpassword"
                label="Confirm Password"
                type="password"
                id="confirmpassword"
                autoComplete="current-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onMouseOver={() => setIsUserInteracting(true)}
              />
              <PasswordChecklist
                rules={[
                  "minLength",
                  "specialChar",
                  "number",
                  "capital",
                  "match",
                ]}
                minLength={8}
                value={password}
                valueAgain={confirmPassword}
                onChange={setPasswordValid}
              />
              {!isLocalhost && (
                <Turnstile
                  sitekey={"0x4AAAAAAAN9OgPZ9sqwzurT"}
                  onVerify={() => {
                    setCapatchaVerified(true);
                  }}
                  refreshExpired="auto"
                />
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{ color: "white" }}
                sx={{ mt: 3, mb: 2 }}
                onMouseOver={() => setIsUserInteracting(true)}
              >
                Sign Up
              </Button>
              <Grid container>
                <Grid item>
                  <Link href="login" variant="body2">
                    Return to Login
                  </Link>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
}
