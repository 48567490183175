export interface UserCourseProgress {
  [courseName: string]: UserLessonProgress;
}

export interface UserLessonProgress {
  [lessonName: string]: QuestionsAnswered;
}

export interface QuestionsAnswered {
  [questionNumber: number]: QuestionAnswerState;
}

export enum QuestionAnswerState {
  Unanswered = 0,
  Correct = 1,
  Incorrect = 2,
}
