import React, { useEffect, useRef, useState } from "react";
import Card from "../../../Components/Card/Card";

interface Props {
  embedId: string;
}

const YouTubeCard = ({ embedId }: Props) => {
  const ref = useRef<HTMLIFrameElement>(null);
  const [youtubePlayerHeight, setYoutubePlayerHeight] = useState(
    (window.innerWidth / 16) * 9
  );

  // Adjust the YouTube player's height based on the 16:9 aspect ratio
  useEffect(() => {
    const onResize = (
      r: React.RefObject<HTMLIFrameElement>,
      setHeight: React.Dispatch<React.SetStateAction<number>>
    ) => {
      const width = r.current?.offsetWidth;

      if (!width) {
        return;
      }

      const height = (width / 16) * 9;

      setHeight(height);
    };

    onResize(ref, setYoutubePlayerHeight);

    window.addEventListener("resize", () =>
      onResize(ref, setYoutubePlayerHeight)
    );

    return () =>
      window.removeEventListener("resize", () =>
        onResize(ref, setYoutubePlayerHeight)
      );
  }, [ref]);

  return (
    <Card noPadding>
      <iframe
        ref={ref}
        width="100%"
        height={youtubePlayerHeight}
        src={`https://www.youtube.com/embed/${embedId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Lesson Video"
        style={{ borderRadius: "10px", borderWidth: 0 }}
      />
    </Card>
  );
};

export default YouTubeCard;
