import { createTheme } from "@mui/material/styles";

export const getLightModeTheme = () => {
  const defaultTheme = createTheme();

  const newTheme = createTheme({
    palette: {
      primary: {
        main: "#109ee0",
      },
      secondary: {
        main: "#f0b03a",
      },
      background: {
        default: "#ffffff",
        paper: "#ffffff",
      },
      text: {
        primary: "#4f4f4f",
        secondary: "#757575",
      },
      success: {
        main: "#4fd170",
      },
      error: {
        main: "#db465f",
      },
    },
    typography: {
      ...defaultTheme.typography,
      fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif`,
      fontSize: 16,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      button: {
        ...defaultTheme.typography.button,
        textTransform: "capitalize",
      },
    },
  });

  return newTheme;
};
