import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  List,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { Drawer } from "./NavBarSubComponents";
import { ChevronLeft, Logout, Menu, Person, Send } from "@mui/icons-material";
import { Constants } from "../Constants";
import { isMobile } from "react-device-detect";
import NavBarItem from "./NavBarItem";
import { Course } from "../Api/Types/Course";
import { getCourse, getCourseAnswers } from "../Api/Endpoints/lessonsCalls";
import { UserLessonProgress } from "../Api/Types/UserProgress";
import { GetCourseUrl, GetLessonUrl, GetPastPaperUrl } from "../Pages/Router";

const NavBar = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(!isMobile);
  const [courseContents, setCourseContents] = useState<Course>();
  const [courseAnswers, setCourseAnswers] = useState<UserLessonProgress>();
  const [cachedCourseId, setCachedCourseId] = useState("");
  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const pathNames = window.location.pathname?.split("/");

    // Invaild path
    if (!pathNames || pathNames.length < 3) {
      setCachedCourseId("");
      setCourseContents(undefined);
      return;
    }

    const courseId = pathNames[2];

    if (courseId !== cachedCourseId) {
      setCachedCourseId(courseId);

      getCourse(courseId)
        .then((x) => {
          if (x.data) {
            setCourseContents(x.data);
          }
        })
        .catch(() => {
          console.log("Could not get course");
        });

      getCourseAnswers(courseId)
        .then((x) => {
          if (x.data) {
            setCourseAnswers(x.data);
          }
        })
        .catch(() => {
          console.log("Could not get course answers");
        });
    }
  }, [window.location.href, cachedCourseId]);

  return (
    <>
      {isMobile && (
        <AppBar
          position="absolute"
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <IconButton
            edge="start"
            aria-label="menu"
            sx={{ width: "50px", ml: 2 }}
            onClick={() => setOpen((x) => !x)}
          >
            <Menu sx={{ color: theme.palette.background.paper }} />
          </IconButton>

          <Box
            sx={{
              position: "absolute",
              top: -8,
              left: "40%",
            }}
          >
            <a className="navbar-brand" href="/home">
              <img
                src="/Logo-white.webp"
                alt={Constants.APP_NAME}
                style={{ maxHeight: "50px" }}
              />
            </a>
          </Box>
        </AppBar>
      )}
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          maxHeight: "100vh",
          display: open ? undefined : "none",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: open ? "space-between" : "center",
            px: open ? [2] : [0],
          }}
        >
          {open && !isMobile && (
            <a
              className="navbar-brand"
              href="/home"
              style={{ width: "100%", textAlign: "center" }}
            >
              <img
                src="/Logo-primary.webp"
                alt={Constants.APP_NAME}
                style={{ maxHeight: "50px" }}
              />
            </a>
          )}
          {isMobile && (
            <IconButton onClick={toggleDrawer}>
              <ChevronLeft
                sx={{
                  transform: open ? "rotate(0deg)" : "rotate(180deg)",
                  transition: "transform .4s linear",
                }}
              />
            </IconButton>
          )}
        </Toolbar>
        <Divider />
        <List
          component="nav"
          sx={{
            height: "calc(100vh - 64px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ overflowY: "scroll" }}>
            <NavBarItem text="Lessons" url={GetCourseUrl(cachedCourseId)} />
            <NavBarItem
              text="Past Papers"
              url={GetPastPaperUrl(cachedCourseId)}
            />
            {courseContents && (
              <>
                <Divider sx={{ mt: 2, mx: 4, mb: 2 }} />
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    ml: 2,
                    color: theme.palette.grey[500],
                  }}
                  color="GrayText"
                >
                  Course Contents
                </Typography>
                {courseContents.topics.flatMap((topic, topicIndex) => (
                  <Box key={`topic${topicIndex}`}>
                    <NavBarItem
                      text={`${topicIndex + 1} - ${topic.name}`}
                      url={undefined}
                    />
                    {topic.lessons.map((lesson, lessonIndex) => (
                      <NavBarItem
                        key={`topic${topicIndex}lesson${lessonIndex}`}
                        text={`${topicIndex + 1}.${lessonIndex + 1} - ${
                          lesson.name
                        }`}
                        url={GetLessonUrl(lesson.url, cachedCourseId)}
                        progressCircleData={
                          courseAnswers && lesson.url in courseAnswers
                            ? courseAnswers[lesson.url]
                            : undefined
                        }
                      />
                    ))}
                  </Box>
                ))}
              </>
            )}
          </Box>

          <Divider />
          <Box>
            <NavBarItem icon={<Send />} text="Contact Us" url="/contact" />
            <NavBarItem icon={<Person />} text="Account" url="/account" />
            <NavBarItem icon={<Logout />} text="Log Out" url="/logout" />
          </Box>
        </List>
      </Drawer>
    </>
  );
};

export default NavBar;
