import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useState } from "react";
import { IsEmailValid } from "./SignUpHelper";
import { LoadingButton } from "@mui/lab";
import { SetSnackbar } from "../../Context/SnackbarContext";
import { triggerPasswordReset } from "../../Api/Endpoints/userCalls";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const setSnackbar = React.useContext(SetSnackbar);
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Check for validation errors
    const emailVal = IsEmailValid(email);

    if (!emailVal) {
      setEmailValid(emailVal);
      return;
    }

    setLoading(true);

    triggerPasswordReset(email)
      .then((x) => {
        setLoading(false);
        setSnackbar({ message: x.message, status: "success" });
        setEmailSent(true);
      })
      .catch(() =>
        setSnackbar({
          message: "An error occurred, please try again later",
          status: "error",
        })
      );
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <AccountCircleIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {emailSent ? "Please Check Your Inbox" : "Password Reset"}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {emailSent ? (
            <>
              <Typography sx={{ mt: 5, mb: 5 }}>
                We&apos;ve sent you an email with a link to reset your password.
              </Typography>

              <LoadingButton
                fullWidth
                variant="contained"
                style={{ color: "white" }}
                sx={{ mt: 3, mb: 2 }}
                loading={false}
                onClick={() => (window.location.href = "/")}
              >
                Return Home
              </LoadingButton>
            </>
          ) : (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!emailValid}
                helperText={!emailValid && "Please enter a valid email address"}
                onBlur={() => setEmailValid(IsEmailValid(email))}
              />

              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                style={{ color: "white" }}
                sx={{ mt: 3, mb: 2 }}
                loading={loading}
              >
                Send Reset Password
              </LoadingButton>
              <Grid container>
                <Grid item>
                  <Link href="login" variant="body2">
                    Return to Login
                  </Link>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
}
