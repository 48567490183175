import React, { useContext } from "react";

import { createBrowserRouter } from "react-router-dom";
import Lesson from "./LessonPage/Lesson";
import Home from "./Home/Home";
import Login from "./Auth/Login";
import SignUp from "./Auth/SignUp";
import ConfirmEmail from "./Auth/ConfirmEmail";
import PasswordReset from "./Auth/PasswordReset";
import Logout from "./Auth/Logout";
import ForgotPassword from "./Auth/ForgotPassword";
import { RouterProvider } from "react-router-dom";
import { UserContext } from "../Context/UserContext";
import NotFound from "./NotFound/NotFound";
import LandingPage from "./LandingPage/LandingPage";
import Course from "./Course/Course";
import Policies from "./LandingPage/Policies";
import PastPaper from "./PastPaper/PastPaper";
import Account from "./AccountPage/Account";
import Contact from "./ContactPage/Contact";

export const loginLocations = [
  "/",
  "/login",
  "/signup",
  "/signup/confirmemail",
  "/resetPassword",
  "/logout",
  "/forgottenPassword",
  "/privacy",
  "/cookies",
  "/terms",
  "/disclaimer",
];

const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/terms",
    element: <Policies policy="Terms" />,
  },
  {
    path: "/privacy",
    element: <Policies policy="Privacy" />,
  },
  {
    path: "/disclaimer",
    element: <Policies policy="Disclaimer" />,
  },
  {
    path: "/cookies",
    element: <Policies policy="Cookie" />,
  },

  // Lesson Views
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/course/*",
    element: <Course />,
  },
  {
    path: "/lesson/*",
    element: <Lesson />,
  },
  {
    path: "/past-papers/*",
    element: <PastPaper />,
  },

  // User Login
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/signup/confirmemail",
    element: <ConfirmEmail />,
  },
  {
    path: "/resetPassword",
    element: <PasswordReset />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/forgottenPassword",
    element: <ForgotPassword />,
  },
  {
    path: "/account",
    element: <Account />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },

  // 404
  {
    path: "*",
    element: <NotFound />,
  },
]);

export const GetCourseUrl = (courseId: string) => `/course/${courseId}`;
export const GetPastPaperUrl = (courseId: string) => `/past-papers/${courseId}`;

export const GetLessonUrl = (lessonUrl: string, courseId: string) =>
  `/lesson/${courseId}/${lessonUrl}`;

const Router = () => {
  const userContext = useContext(UserContext);

  // Check if should redirect to login
  if (
    !userContext?.email &&
    !loginLocations.some((x) => window.location.pathname === x)
  ) {
    window.location.href = "/logout";
  }

  return <RouterProvider router={AppRouter} />;
};

export default Router;
