import React from "react";
import { Box, Typography, Button, Theme } from "@mui/material";
import { Course } from "../../../Api/Types/Course";
import Card from "../../../Components/Card/Card";

interface Props {
  theme: Theme;
  course: Course;
}

const ContentsCard = ({ theme, course }: Props): JSX.Element => (
  <Card>
    <Box sx={{ my: 3 }}>
      <Typography
        variant="h5"
        sx={{
          mb: 1,
          textAlign: "center",
          color: theme.palette.primary.main,
        }}
        fontWeight="400"
      >
        Course Contents
      </Typography>
      <Typography
        sx={{
          mb: 4,
          mt: 0,
          textAlign: "center",
          color: theme.palette.grey[500],
        }}
      >
        Click on the topic that you&apos;d like to study from the list below, or
        scroll down to view the individual lessons.
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: [
            "1fr",
            "1fr",
            "1fr 1fr",
            "1fr 1fr",
            "1fr 1fr 1fr",
          ],
        }}
      >
        {course.topics.map((topic, topicIndex) => (
          <Button
            key={"Contents" + topicIndex}
            onClick={() =>
              document
                .getElementById(`TopicHeading${topicIndex}`)
                ?.scrollIntoView()
            }
            sx={{
              transition: "color 0.25s ease-out",
              color: theme.palette.primary.main,
              textTransform: "none",
              ":hover": {
                color: theme.palette.primary.main,
                backgroundColor: "rgba(0, 0, 0, 0)",
              },
            }}
            disableRipple
          >
            <Typography
              sx={{
                transition: "color 0.25s ease-out",

                textTransform: "none",
                ":hover": {
                  color: theme.palette.primary.main,
                  backgroundColor: "rgba(0, 0, 0, 0)",
                },
              }}
            >
              {topicIndex + 1}. {topic.name}
            </Typography>
          </Button>
        ))}
      </Box>
    </Box>
  </Card>
);

export default ContentsCard;
