import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useTheme, Theme } from "@mui/material/styles";
import { LessonQuestion } from "../../../Api/Types/Lesson";
import NumberTile from "./NumberTile";
import { QuestionAnswerState } from "../../../Api/Types/UserProgress";
import { isMobile } from "react-device-detect";

interface Props {
  question: LessonQuestion;
  questionNumber: number;
  onQuestionAnswered?: (questionState: QuestionAnswerState) => void;
}

const QuestionCard = ({
  question,
  onQuestionAnswered,
  questionNumber,
}: Props) => {
  const theme = useTheme();
  const [userAnswer, setUserAnswer] = useState("");
  const [userAnswered, setUserAnswered] = useState(false);
  const [answerCorrect, setAnswerCorrect] = useState(
    userAnswer === question.exactMatchAnswer
  );

  useEffect(() => {
    setAnswerCorrect(userAnswer === question.exactMatchAnswer);
    onQuestionAnswered &&
      onQuestionAnswered(
        userAnswered
          ? userAnswer === question.exactMatchAnswer
            ? QuestionAnswerState.Correct
            : QuestionAnswerState.Incorrect
          : QuestionAnswerState.Unanswered
      );
  }, [userAnswered, userAnswer]);

  return (
    <Box sx={{ mb: 10 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: isMobile ? "wrap" : "nowrap",
        }}
      >
        <NumberTile number={questionNumber} />
        {isMobile && (
          <>
            <br />
            <br />
          </>
        )}
        <Typography
          fontWeight="300"
          align="center"
          sx={{ whiteSpace: "pre-wrap", mb: 4, mt: -4 }}
        >
          {question.question}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        flexDirection="column"
        alignContent="center"
        justifyContent="center"
        sx={{ textAlign: "center", alignItems: "center" }}
      >
        {question.type === "typed" ? (
          <ExactAnswerQuestion
            userAnswered={userAnswered}
            answerCorrect={answerCorrect}
            userAnswer={userAnswer}
            setUserAnswer={setUserAnswer}
            theme={theme}
          />
        ) : (
          <MultipleChoiceAnswerQuestion
            userAnswered={userAnswered}
            setUserAnswered={setUserAnswered}
            answerCorrect={answerCorrect}
            userAnswer={userAnswer}
            setUserAnswer={setUserAnswer}
            theme={theme}
            choices={question.choices || []}
          />
        )}

        {userAnswered && (
          <Typography sx={{ maxWidth: "800px", textAlign: "center", my: 3 }}>
            <span
              style={{
                color: answerCorrect
                  ? theme.palette.success.main
                  : theme.palette.error.main,
              }}
            >
              {answerCorrect ? "Well Done!" : "Not Quite."}
            </span>{" "}
            {question.reasoning}
          </Typography>
        )}
        {/* Multi-choice questions only want this button shown to reset, otherwise always show it */}
        {(userAnswered || question.type !== "multiple-choice") && (
          <Button
            variant="contained"
            onClick={() => {
              setUserAnswered((x) => !x);
            }}
            sx={{ width: "250px" }}
          >
            {userAnswered
              ? answerCorrect
                ? "Reset"
                : "Try Again"
              : "Check Answer"}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default QuestionCard;

const ExactAnswerQuestion = (props: {
  userAnswered: boolean;
  answerCorrect: boolean;
  userAnswer: string;
  setUserAnswer: React.Dispatch<React.SetStateAction<string>>;
  theme: Theme;
}) => (
  <TextField
    color={
      props.userAnswered
        ? props.answerCorrect
          ? "success"
          : "error"
        : undefined
    }
    inputProps={{
      disabled: props.userAnswered,
      style: {
        textAlign: "center",
        padding: 8,
        color: props.userAnswered
          ? props.answerCorrect
            ? props.theme.palette.background.paper
            : props.theme.palette.error.main
          : undefined,
      },
    }}
    placeholder="Enter Your Answer Here"
    value={props.userAnswer}
    onChange={(e) => props.setUserAnswer(e.target.value)}
    sx={{
      width: "250px",
      marginBottom: "20px",
      background:
        props.userAnswered && props.answerCorrect
          ? props.theme.palette.success.main
          : undefined,
    }}
    error={props.userAnswered && !props.answerCorrect}
  />
);

const MultipleChoiceAnswerQuestion = (props: {
  userAnswered: boolean;
  setUserAnswered: React.Dispatch<React.SetStateAction<boolean>>;
  answerCorrect: boolean;
  userAnswer: string;
  setUserAnswer: React.Dispatch<React.SetStateAction<string>>;
  theme: Theme;
  choices: string[];
}) => (
  <Box
    display="flex"
    flexWrap="wrap"
    flexDirection={"column"}
    alignContent="center"
    justifyContent="center"
    gap={isMobile ? 1.5 : 2}
    sx={{
      textAlign: "center",
      alignItems: "center",
      maxWidth: "800px",
      mx: 10,
    }}
  >
    {props.choices.map((choice) => (
      <Button
        key={"multiple-choice" + props.choices.join(",") + choice}
        variant={
          props.userAnswered &&
          props.answerCorrect &&
          choice === props.userAnswer
            ? "contained"
            : "outlined"
        }
        disableRipple={props.userAnswered}
        disableElevation={props.userAnswered}
        disableFocusRipple={props.userAnswered}
        disableTouchRipple={props.userAnswered}
        sx={{
          width: "300px",
          cursor: props.userAnswered ? "default" : "pointer",
          background:
            props.userAnswered &&
            choice === props.userAnswer &&
            props.answerCorrect
              ? props.theme.palette.success.main
              : undefined,
          color:
            props.userAnswered && choice === props.userAnswer
              ? props.answerCorrect
                ? props.theme.palette.background.paper
                : props.theme.palette.error.main
              : undefined,
          borderColor:
            props.userAnswered &&
            choice === props.userAnswer &&
            !props.answerCorrect
              ? props.theme.palette.error.main
              : undefined,
          "&:hover": {
            background:
              props.userAnswered &&
              choice === props.userAnswer &&
              props.answerCorrect
                ? props.theme.palette.success.main
                : undefined,
            borderColor:
              props.userAnswered &&
              choice === props.userAnswer &&
              !props.answerCorrect
                ? props.theme.palette.error.main
                : undefined,
          },
        }}
        onClick={() => {
          if (props.userAnswered) {
            return;
          }
          props.setUserAnswer(choice);
          props.setUserAnswered(true);
        }}
      >
        {choice}
      </Button>
    ))}
  </Box>
);
