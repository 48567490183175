import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { UserContext, UserDispatchContext } from "../../Context/UserContext";
import { SetSnackbar } from "../../Context/SnackbarContext";
import { userLogin } from "../../Api/Endpoints/userCalls";

export default function Login() {
  const userContext = useContext(UserContext);
  const setUserContext = useContext(UserDispatchContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const setSnackbar = useContext(SetSnackbar);
  const [searchParams] = useSearchParams();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    userLogin({
      id: "",
      email: email,
      password: password,
      name: "",
      confirmationCodes: {},
    })
      .then((x) => {
        if (x.data !== undefined) {
          setUserContext(x.data);
          // eslint-disable-next-line no-restricted-globals
          location.replace("/home");
        } else {
          setSnackbar({
            message: x.message,
            status: "error",
          });
        }
      })
      .catch(() =>
        setSnackbar({
          message:
            "Could not login. Please check your credentials and try again",
          status: "error",
        })
      );
  };

  useEffect(() => {
    // If there is an error message included in the search params, clear the user data
    const error = searchParams.get("e");
    if (error !== null) {
      if (userContext?.email !== undefined) {
        setUserContext({
          email: "",
          id: "",
          password: "",
          name: "",
          confirmationCodes: {},
        });
        setSnackbar({
          message: "Please login in order to complete that action",
          status: "warning",
        });
      }
    }
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{ color: "white" }}
            sx={{ mt: 3, mb: 2 }}
          >
            Login
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="forgottenPassword" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
