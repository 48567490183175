import { Constants } from "../../Constants";

export interface ApiResponse<TResponseType> {
  message: string;
  data: TResponseType;
}

export const fetchBase = <TResponseType>(
  route: string,
  method: "GET" | "POST" | "DELETE",
  body?: object,
  isFile?: boolean
): Promise<ApiResponse<TResponseType>> =>
  fetch(Constants.BASE_URL + "/api/" + route, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: method,
    body: body ? JSON.stringify(body) : undefined,
  }).then(async (x) => {
    // Redirect to logout if we receive a 401
    if (x.status && x.status === 401) {
      window.location.href = "/logout";
      return;
    }

    if (isFile) {
      return x.blob();
    }

    return await x.json();
  });
