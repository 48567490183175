import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useState } from "react";
import PasswordChecklist from "react-password-checklist";
import { LoadingButton } from "@mui/lab";
import { useSearchParams } from "react-router-dom";
import { SetSnackbar } from "../../Context/SnackbarContext";
import { resetPassword } from "../../Api/Endpoints/userCalls";

export default function PasswordReset() {
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const setSnackbar = React.useContext(SetSnackbar);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const token = searchParams.get("token");
    const email = searchParams.get("email");

    if (!passwordValid) {
      return;
    }
    setLoading(true);

    console.log(token);
    console.log(password);
    console.log(email);

    // Password Reset
    resetPassword(token || "", password, email || "")
      .then(() => {
        setSnackbar({
          message: "Successfully reset password. Please sign in",
          status: "success",
        });
        window.location.href = "/login";
      })
      .catch(() => {
        setSnackbar({
          message: "An error occurred. Please try again later",
          status: "error",
        });
        setLoading(false);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <AccountCircleIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmpassword"
            label="Confirm Password"
            type="password"
            id="confirmpassword"
            autoComplete="current-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <PasswordChecklist
            rules={["minLength", "specialChar", "number", "capital", "match"]}
            minLength={8}
            value={password}
            valueAgain={confirmPassword}
            onChange={setPasswordValid}
          />

          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            style={{ color: "white" }}
            loading={loading}
          >
            Reset Password
          </LoadingButton>
          <Grid container>
            <Grid item>
              <Link href="login" variant="body2">
                Return to Login
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
