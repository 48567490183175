import React from "react";
import { Grid, Typography, Box, Theme } from "@mui/material";

import { isMobile } from "react-device-detect";
import Card from "../../../Components/Card/Card";
import { Course as CourseType } from "../../../Api/Types/Course";

interface Props {
  course: CourseType;
  theme: Theme;
}

const TitleCard = ({ course, theme }: Props): JSX.Element => (
  <Grid item xs={12} lg={8} marginTop={isMobile ? 2 : 6}>
    <Card
      title={course.title + " Lessons"}
      titleAlign={isMobile ? "center" : "left"}
    >
      <Typography
        fontWeight="400"
        sx={{
          color: theme.palette.grey[500],
          mb: 4,
          ml: 2,
          textAlign: isMobile ? "center" : "left",
        }}
      >
        {course.subtitle}
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          gap: isMobile ? undefined : "100px",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            mb: 4,
            textAlign: isMobile ? "center" : "left",
          }}
        >
          {course.description}
        </Typography>
      </Box>
    </Card>
  </Grid>
);

export default TitleCard;
