import React, { useEffect, useState } from "react";
import Card from "../../../Components/Card/Card";
import { Box, Typography, useTheme } from "@mui/material";
import QuestionCard from "./QuestionCard";
import { LessonQuestion } from "../../../Api/Types/Lesson";

import {
  QuestionAnswerState,
  QuestionsAnswered,
} from "../../../Api/Types/UserProgress";
import { saveLessonAnswers } from "../../../Api/Endpoints/lessonsCalls";

interface Props {
  courseId: string;
  lessonId: string;
  questions: LessonQuestion[];
}

const QuestionContainer = ({ questions, courseId, lessonId }: Props) => {
  const theme = useTheme();

  // A list of question numbers that the user has answered correctly
  const [questionStates, setQuestionStates] = useState<QuestionsAnswered>({});
  const [reRendered, reRender] = useState(false);
  const [shouldSaveAnswers, setShouldSaveAnswers] = useState(false);

  useEffect(() => {
    reRender((x) => !x);
  }, [questions]);

  useEffect(() => {
    if (
      shouldSaveAnswers &&
      Object.values(questionStates).some(
        (x) => x !== QuestionAnswerState.Unanswered
      )
    ) {
      setShouldSaveAnswers(false);
      console.log("SAVING");
      console.log(questionStates);
      saveLessonAnswers(lessonId, courseId, questionStates);
    }
  }, [reRendered, shouldSaveAnswers]);

  return (
    <Card>
      <Box>
        <Typography
          align="center"
          sx={{
            whiteSpace: "pre-wrap",
            mb: 4,
            color: theme.palette.primary.main,
          }}
          variant="h5"
        >
          Test Your Knowledge
        </Typography>

        {questions.map((question, questionNumber) => (
          <QuestionCard
            question={question}
            questionNumber={questionNumber + 1}
            key={questionNumber}
            onQuestionAnswered={(answerState) => {
              reRender((x) => !x);

              setShouldSaveAnswers(true);

              setQuestionStates((x) => {
                x[questionNumber] = answerState;
                return x;
              });
            }}
          />
        ))}
      </Box>
    </Card>
  );
};

export default QuestionContainer;
