import React, { useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { UserContext, UserDispatchContext } from "../../Context/UserContext";
import Card from "../../Components/Card/Card";
import { isMobile } from "react-device-detect";
import { IsEmailValid, IsOrgNameValid } from "../Auth/SignUpHelper";
import { deleteAccount, updateAccount } from "../../Api/Endpoints/userCalls";
import { SetSnackbar } from "../../Context/SnackbarContext";
import { LoadingButton } from "@mui/lab";
import GenericModal from "../../Components/GenericModal/GenericModal";

import { LoggedOutUser } from "../Auth/Logout";

export default function Account() {
  const user = useContext(UserContext);
  const theme = useTheme();
  const setUserContext = useContext(UserDispatchContext);
  const setSnackbar = useContext(SetSnackbar);
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);

  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      paddingX={{ xs: 0, x: 0, s: 4, m: 7, l: 8, xl: 10 }}
    >
      <GenericModal
        open={deleteAccountModalOpen}
        title={"Delete Account"}
        onClose={(cancelled) => {
          if (!cancelled) {
            setLoading(true);
            deleteAccount()
              .then(() => {
                setUserContext(LoggedOutUser);

                window.location.href = "/";
              })
              .catch(() =>
                setSnackbar({
                  status: "error",
                  message:
                    "Your account couldn't be deleted. Please try again later, or contact support",
                })
              )
              .finally(() => {
                setLoading(false);
              });
          }

          setDeleteAccountModalOpen(false);
        }}
        loading={loading}
        actionButtonText={"Delete Account"}
      >
        <Typography>
          Are you sure that you want to delete your account. This action will
          remove all of your lesson history and cannot be undone
        </Typography>
      </GenericModal>
      <Card title="Account Settings">
        <>
          <Typography>
            Update your account settings or close your account here.
          </Typography>

          <Typography
            variant="h5"
            sx={{
              mt: 4,
              mb: 1,
              textAlign: "left",
              color: theme.palette.primary.main,
            }}
            fontWeight="400"
          >
            Account Settings
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 5,
              mt: 3,
            }}
          >
            <TextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              onBlur={(e) => setNameError(!IsOrgNameValid(e.target.value))}
              fullWidth
              error={nameError}
              helperText={nameError ? "Please enter a valid name" : undefined}
            />
            <TextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={(e) => setEmailError(!IsEmailValid(e.target.value))}
              fullWidth
              error={emailError}
              helperText={
                emailError ? "Please enter a valid email address" : undefined
              }
            />
          </Box>
          <LoadingButton
            loading={loading}
            variant="outlined"
            onClick={() => {
              const emailVal = IsEmailValid(email);
              const uNameValid = IsOrgNameValid(name);

              if (!emailVal) {
                setEmailError(true);
                return;
              }
              if (!uNameValid) {
                setEmailError(false);
                setNameError(true);
                return;
              }

              setEmailError(false);
              setNameError(false);
              setLoading(true);

              updateAccount(name, email)
                .then((x) => {
                  if (x.data) {
                    setUserContext(x.data);
                    setSnackbar({ status: "success", message: "User updated" });
                  } else {
                    setSnackbar({
                      status: "error",
                      message: "An error occurred. Please try again later",
                    });
                  }
                })
                .catch(() =>
                  setSnackbar({
                    status: "error",
                    message: "An error occurred. Please try again later",
                  })
                )
                .finally(() => setLoading(false));
            }}
            sx={{ width: isMobile ? "100%" : "200px", mt: 3 }}
          >
            Update Settings
          </LoadingButton>

          <Button
            variant="text"
            color="error"
            onClick={() => {
              setDeleteAccountModalOpen(true);
            }}
            sx={{ width: isMobile ? "100%" : "200px", mt: 5 }}
          >
            Close Your Account
          </Button>
        </>
      </Card>
    </Grid>
  );
}
