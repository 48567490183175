import React from "react";
import {
  Box,
  Typography,
  Button,
  Theme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Course } from "../../../Api/Types/Course";
import Card from "../../../Components/Card/Card";
import { isMobile } from "react-device-detect";

interface Props {
  theme: Theme;
  course: Course;
}

const PastPapersCard = ({ theme, course }: Props): JSX.Element => (
  <Card>
    <Box sx={{ my: 3 }}>
      <Typography
        variant="h5"
        sx={{
          mb: 1,
          textAlign: "center",
          color: theme.palette.primary.main,
        }}
        fontWeight="400"
      >
        Past Papers
      </Typography>
      {course.pastPapers ? (
        <TableContainer>
          <Table>
            <TableBody>
              {course.pastPapers.map((paper) => (
                <TableRow
                  key={paper.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:nth-of-type(odd)": {
                      backgroundColor: theme.palette.grey[100],
                    },
                    "&:nth-of-type(even)": {
                      backgroundColor: theme.palette.grey[50],
                    },
                    textWrap: isMobile ? undefined : "nowrap",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ width: "100%", borderBottom: "none" }}
                  >
                    {paper.name}
                  </TableCell>
                  <TableCell
                    sx={{ width: "fit-content", borderBottom: "none" }}
                  >
                    <Button variant="outlined" href={paper.paperLink}>
                      Paper
                    </Button>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "fit-content",
                      textAlign: "center",
                      borderBottom: "none",
                    }}
                  >
                    <Button variant="outlined" href={paper.markSchemeLink}>
                      Mark Scheme
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No Past Papers To Show At This Time</Typography>
      )}
    </Box>
  </Card>
);

export default PastPapersCard;
