import React from "react";
import { Box, Typography, Container, Button } from "@mui/material";

import { styled } from "@mui/material/styles";

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

interface Props {
  hideHomeButton?: boolean;
}
function NotFound(props: Props) {
  return (
    <>
      <MainContent>
        <Container maxWidth="md">
          <Box textAlign="center">
            <Typography variant="h4" sx={{ my: 2, mb: 10, mt: 10 }}>
              The page you were looking for doesn&apos;t exist.
            </Typography>
            {props.hideHomeButton !== true && (
              <Button href="/home" variant="outlined">
                Home
              </Button>
            )}
          </Box>
        </Container>
      </MainContent>
    </>
  );
}

export default NotFound;
