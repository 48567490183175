import React, { useContext, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { UserDispatchContext } from "../../Context/UserContext";
import User from "../../Api/Types/User";

export const LoggedOutUser: User = {
  email: "",
  password: "",
  id: "",
  name: "",
  confirmationCodes: {},
};

const Logout = () => {
  const setUserContext = useContext(UserDispatchContext);

  useEffect(() => {
    setUserContext(LoggedOutUser);

    window.location.href = "/login";
  });

  return (
    <Box
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Logout;
