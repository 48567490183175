import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import { Course as CourseType } from "../../Api/Types/Course";
import { getCourse } from "../../Api/Endpoints/lessonsCalls";
import { SetSnackbar } from "../../Context/SnackbarContext";
import { Box, CircularProgress } from "@mui/material";
import PastPaperContainer from "./Components/PastPaperContainer";

export default function PastPaper() {
  const [course, setCourse] = useState<CourseType>();
  const [loading, setLoading] = useState(true);
  const [courseId, setCourseId] = useState<string>();
  const setSnackbar = useContext(SetSnackbar);

  useEffect(() => {
    const pathNames = window.location.pathname?.split("/");

    // Invaild path
    if (!pathNames || pathNames.length < 3) {
      setLoading(false);
      return;
    }

    const courseId = pathNames[2];
    setCourseId(courseId);

    setLoading(true);
    getCourse(courseId)
      .then((x) => {
        if (x.data) {
          setCourse(x.data);
        } else {
          setSnackbar({ message: x.message, status: "error" });
        }

        setLoading(false);
      })
      .catch(() => {
        setSnackbar({
          message: "Could not get past paper details. Please try again later",
          status: "error",
        });

        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else if (course === undefined) {
    window.location.href = "/home";
    return <></>;
  }

  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      paddingX={{ xs: 0, x: 0, s: 4, m: 7, l: 8, xl: 10 }}
      sx={{ scrollBehavior: "smooth" }}
    >
      <PastPaperContainer courseId={courseId} course={course} />
    </Grid>
  );
}
