import React from "react";
import { Box, Typography, Button, Theme } from "@mui/material";
import LessonProgress from "../../../Components/LessonProgress/LessonProgress";
import { Topic } from "../../../Api/Types/Course";
import { UserLessonProgress } from "../../../Api/Types/UserProgress";
import Card from "../../../Components/Card/Card";
import { GetLessonUrl } from "../../Router";

interface Props {
  courseAnswers: UserLessonProgress | undefined;
  topicIndex: number;
  topic: Topic;
  theme: Theme;
  courseId: string | undefined;
}

const LessonsCard = ({
  courseAnswers,
  topicIndex,
  topic,
  theme,
  courseId,
}: Props): JSX.Element => (
  <Card>
    <Box sx={{ my: 3 }}>
      <Typography
        variant="h5"
        sx={{
          mb: 4,
          textAlign: "center",
          color: theme.palette.primary.main,
        }}
        fontWeight="400"
        id={`TopicHeading${topicIndex}`}
      >
        {topicIndex + 1}. {topic.name}
      </Typography>
      <Box
        sx={{
          mx: 3,
          display: "grid",
          gridTemplateColumns: ["1fr", "1fr", "1fr", "1fr 1fr", "1fr 1fr"],
        }}
      >
        {topic.lessons.map((lesson, lessonIndex) => (
          <Button
            key={topicIndex + "-" + lessonIndex}
            href={GetLessonUrl(lesson.url, courseId || "")}
            sx={{
              ml: 5,
              transition: "color 0.25s ease-out",
              color: theme.palette.grey[600],
              textTransform: "none",
              ":hover": {
                color: theme.palette.primary.main,
                backgroundColor: "rgba(0, 0, 0, 0)",
              },
            }}
            disableRipple
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 0.5,
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  transition: "color 0.25s ease-out",
                  textTransform: "none",
                  ":hover": {
                    color: theme.palette.primary.main,
                    backgroundColor: "rgba(0, 0, 0, 0)",
                  },
                }}
              >
                {topicIndex + 1}.{lessonIndex + 1} - {lesson.name}
              </Typography>
              {courseAnswers && (
                <Box sx={{ width: ["100%", "100%", "300px", "300px"] }}>
                  <LessonProgress
                    questionsAnswered={
                      courseAnswers[lesson.url]
                        ? JSON.parse(
                            courseAnswers[lesson.url] as unknown as string
                          )
                        : []
                    }
                  />
                </Box>
              )}
            </Box>
          </Button>
        ))}
      </Box>
    </Box>
  </Card>
);

export default LessonsCard;
