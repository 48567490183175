import { Alert, Snackbar as MuiSnackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SnackbarContent } from "../../Context/SnackbarContext";

interface Props {
  content: SnackbarContent | undefined;
}

const Snackbar = (props: Props): JSX.Element => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.content !== undefined);
  }, [props.content]);

  return (
    <MuiSnackbar
      open={open}
      autoHideDuration={6000}
      onClose={() => setOpen(false)}
    >
      <Alert
        onClose={() => setOpen(false)}
        severity={props.content?.status}
        sx={{ width: "100%" }}
      >
        {props.content?.message}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
