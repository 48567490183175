import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";

interface Props {
  title?: string;
  titleAlign?: "center" | "left";
  children: string | JSX.Element | JSX.Element[];
  noPadding?: boolean;
  height?: string;
}

const Card = ({ title, children, noPadding, titleAlign, height }: Props) => (
  <Grid item xs={12} md={12} lg={12} sx={{ height: height }}>
    <Paper
      sx={{
        p: noPadding ? 0 : { xs: 2, x: 2, s: 4, m: 6, l: 6, xl: 6 },
        px: noPadding ? 0 : { xs: 2, x: 2, s: 4, m: 6, l: 8, xl: 8 },
        display: "flex",
        flexDirection: "column",
        borderRadius: "10px",
        height: height,
      }}
      style={{
        paddingLeft: noPadding ? 0 : isMobile ? 10 : 50,
        paddingRight: noPadding ? 0 : isMobile ? 10 : 50,
        paddingTop: noPadding ? 0 : isMobile ? 20 : 30,
        paddingBottom: noPadding ? 0 : isMobile ? 20 : 30,
      }}
    >
      {title && (
        <Typography
          component="h2"
          variant="h4"
          color="primary"
          gutterBottom
          align={titleAlign || "center"}
          sx={{ mb: titleAlign ? 0 : 4, fontWeight: "400", fontSize: "35px" }}
        >
          {title}
        </Typography>
      )}
      {children}
    </Paper>
  </Grid>
);

export default Card;
