import React, { createContext, useReducer } from "react";

import { StorageAccess } from "./CookieStorage";
import User from "../Api/Types/User";

interface Props {
  children?: JSX.Element;
}

const UserStore = ({ children }: Props): React.ReactElement => {
  const CURRENTUSER_KEY = "currentUser";

  const getCurrentUser = (): User => {
    const store = StorageAccess.userStorage;
    const user: string = store?.fetch(CURRENTUSER_KEY) ?? "";

    if (user.length > 0) {
      try {
        return JSON.parse(user) as User;
      } catch {
        return {} as User;
      }
    }

    return {} as User;
  };

  const userReducer = (prevUser: User, newUser: User): User => {
    const store = StorageAccess.userStorage;
    store?.set(CURRENTUSER_KEY, JSON.stringify(newUser));

    return newUser;
  };

  const [user, dispatchUser] = useReducer(userReducer, getCurrentUser());

  return (
    <UserDispatchContext.Provider value={dispatchUser}>
      <UserContext.Provider value={user}>{children}</UserContext.Provider>
    </UserDispatchContext.Provider>
  );
};

/** Allows the User state to be updated. */
export const UserDispatchContext = createContext(
  (() => 0) as React.Dispatch<User>
);

/** Returns the current user state. */
export const UserContext = createContext({} as User);

export default UserStore;
