import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useSearchParams } from "react-router-dom";
import { styled, Container } from "@mui/material";
import { UserContext, UserDispatchContext } from "../../Context/UserContext";
import { SetSnackbar } from "../../Context/SnackbarContext";
import { confirmEmailAddress } from "../../Api/Endpoints/userCalls";
import { Constants } from "../../Constants";

const Wrapper = styled(Container)(
  () => `
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
    `
);

export default function ConfirmEmail() {
  const [searchParams] = useSearchParams();
  const user = useContext(UserContext);
  const setUserContext = useContext(UserDispatchContext);
  const setSnackbar = useContext(SetSnackbar);

  const [confirmationCode, setConfirmationCode] = useState("");
  const [email, setEmail] = useState("");
  const [confirmationCodeComplete, setConfirmationCodeComplete] =
    useState(false);
  const [confirmationCodeError, setConfirmationCodeError] = useState(false);

  const [loading, setLoading] = useState(false);
  const isChange = searchParams.get("change");

  // On load, autofill the confirmation code if applicable
  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      setConfirmationCode(token);
      setConfirmationCodeComplete(true);
    }
    const email = searchParams.get("email");
    if (email) {
      setEmail(email);
    }
  }, [searchParams]);

  // When the confirmation code has been fully entered, ping the backend
  useEffect(() => {
    if (confirmationCodeComplete) {
      setLoading(true);
      confirmEmailAddress(confirmationCode, email)
        .then((x) => {
          if (!x.data) {
            setSnackbar({
              message: x.message,
              status: "error",
            });

            return;
          }

          setSnackbar({
            message: "Confirmed Email Address",
            status: "success",
          });

          if (x.data) {
            setUserContext(x.data);
          }

          // If this confirmation is an email change, don't continue with setup walk through
          if (isChange === "true") {
            // eslint-disable-next-line no-restricted-globals
            location.replace("/home");
            return;
          }

          // If the user is not logged in, return to login
          if (user === undefined || user === null || user.id === undefined) {
            // eslint-disable-next-line no-restricted-globals
            location.replace("/login");
          }
          // Else continue with user setup
          else {
            // eslint-disable-next-line no-restricted-globals
            location.replace("/home");
          }
        })
        .catch(() => {
          setSnackbar({
            message:
              "Could not confirm email address. Are you sure that the status code is correct. Please contact support if you continue experiencing issues",
            status: "error",
          });
          setConfirmationCodeError(true);
        })
        .finally(() => setLoading(false));
    }
  }, [confirmationCode, confirmationCodeComplete, user]);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography component="h1" variant="h3">
          Welcome to {Constants.APP_NAME}!
        </Typography>
        <Typography component="h1" variant="h5" style={{ marginTop: 20 }}>
          Please click the link in your inbox to confirm your account.
        </Typography>
      </Box>
    </Container>
  );
}
