import React from "react";
import { useTheme } from "@mui/material/styles";
import { UserLessonProgress } from "../../../Api/Types/UserProgress";
import { Course as CourseType } from "../../../Api/Types/Course";
import TitleCard from "./TitleCard";
import LinksCard from "./LinksCard";
import ContentsCard from "./ContentsCard";
import LessonsCard from "./LessonsCard";

interface Props {
  course: CourseType;
  courseId: string | undefined;
  courseAnswers: UserLessonProgress | undefined;
}

const CourseContainer = ({ courseId, course, courseAnswers }: Props) => {
  const theme = useTheme();

  return (
    <>
      <TitleCard course={course} theme={theme} />
      <LinksCard
        course={course}
        courseId={courseId}
        theme={theme}
        courseAnswers={courseAnswers}
      />

      <ContentsCard theme={theme} course={course} />
      {course.topics.map((topic, topicIndex) => (
        <LessonsCard
          courseAnswers={courseAnswers}
          topicIndex={topicIndex}
          topic={topic}
          theme={theme}
          courseId={courseId}
          key={topicIndex}
        />
      ))}
    </>
  );
};

export default CourseContainer;
