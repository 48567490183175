import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface Props {
  title: string | JSX.Element;
  description?: string | JSX.Element;
  open: boolean;
  onClose: (cancelled: boolean) => void;
  children: JSX.Element;
  loading: boolean;
  actionButtonText: string;
  hideCancel?: boolean;
  showMedium?: boolean;
}

const GenericModal = (props: Props): JSX.Element => {
  return (
    <Dialog
      open={props.open}
      maxWidth={props.showMedium ? "sm" : "lg"}
      fullWidth
    >
      <DialogTitle fontSize={"20px"} style={{ marginBottom: 0 }}>
        {props.title}
        {props.description}
      </DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        {props.hideCancel !== true && (
          <Button onClick={() => !props.loading && props.onClose(true)}>
            Cancel
          </Button>
        )}
        <LoadingButton
          onClick={() => !props.loading && props.onClose(false)}
          loading={props.loading}
        >
          {props.actionButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default GenericModal;
