import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface Props {
  number: number;
}

const NumberTile = ({ number }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette.primary.main,
        color: theme.palette.background.paper,
        width: "25px",
        height: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mr: 2,
        borderRadius: "5px",
        padding: "5px",
      }}
    >
      <Typography sx={{ color: theme.palette.background.paper }}>
        {number}
      </Typography>
    </Box>
  );
};

export default NumberTile;
