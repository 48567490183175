import React from "react";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { QuestionsAnswered } from "../Api/Types/UserProgress";

interface Props {
  icon?: JSX.Element;
  text: string;
  url: string | undefined;
  style?: React.CSSProperties;
  progressCircleData?: QuestionsAnswered;
}

const NavBarItem = ({ icon, text, url, style }: Props) => {
  const theme = useTheme();

  const pageSelected = url && window.location.pathname === url;

  return (
    <ListItemButton
      href={url || ""}
      disabled={url === undefined}
      style={style}
      sx={{
        paddingLeft: 2,
        maxHeight: "60px",
        borderLeft: pageSelected
          ? `solid 4px ${theme.palette.primary.main}`
          : undefined,
        backgroundColor: pageSelected ? theme.palette.action.hover : undefined,
        ":hover": {
          backgroundColor: pageSelected
            ? theme.palette.action.selected
            : theme.palette.action.hover,
        },
      }}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}

      <ListItemText
        primary={text}
        primaryTypographyProps={{
          sx: {
            fontSize: 16,
            fontWeight: 300,
            overflowX: "hidden",
            textOverflow: "ellipsis",
          },
        }}
      />
    </ListItemButton>
  );
};

export default NavBarItem;
