import React, { useContext } from "react";
import { Typography } from "@mui/material";
import { UserContext } from "../../Context/UserContext";
import { IsUserLoggedIn } from "../../Utils/IsUserLoggedIn";

const Home = (): JSX.Element => {
  const user = useContext(UserContext);

  if (IsUserLoggedIn(user)) {
    window.location.href = "/course/ocr-computer-science-gcse";
  } else {
    window.location.href = "/logout";
  }
  return (
    <>
      <Typography>Welcome</Typography>
      <Typography>You will be redirected to the right page shortly</Typography>
    </>
  );
};

export default Home;
