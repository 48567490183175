import React, { useContext } from "react";
import { Box } from "@mui/material";
import { UserContext } from "../../Context/UserContext";
import "./assets/css/bootstrap.min.css";
import "./assets/css/tiny-slider.css";
import "./assets/css/lineicons.css";
import "./assets/css/glightbox.min.css";
import "./style.css";
import { Constants } from "../../Constants";
import Markdown from "markdown-to-jsx";

// Not an ideal react component, but this is an HTML page from the following link, quickly ported across: https://onepagelove.com/ayro-business

const Policies = ({
  policy,
}: {
  policy: "Cookie" | "Terms" | "Disclaimer" | "Privacy";
}): JSX.Element => {
  const user = useContext(UserContext);

  let policyBody = "";

  switch (policy) {
    case "Cookie":
      policyBody = CookiesPolicy;
      break;
    case "Terms":
      policyBody = TermsPolicy;
      break;
    case "Disclaimer":
      policyBody = Disclaimer;
      break;
    case "Privacy":
      policyBody = PrivacyMarkdown;
      break;
  }
  return (
    <Box sx={{ width: "100vw" }}>
      <section className="navbar-area navbar-nine">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg">
                <a className="navbar-brand" href="/">
                  <img
                    src="/Logo-white.webp"
                    alt="Logo"
                    style={{ maxHeight: "50px" }}
                  />
                </a>

                <div
                  className="collapse navbar-collapse sub-menu-bar"
                  id="navbarNine"
                >
                  <ul className="navbar-nav me-auto">
                    <li className="nav-item">
                      <a className="page-scroll" href="/">
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="page-scroll"
                        href={user ? "/home" : "/signup"}
                      >
                        {user ? "Login" : "Sign Up"}
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section
        id="policy"
        className="services-area services-eight"
        style={{ marginTop: "100px" }}
      >
        <div className="section-title-five">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <Markdown>{policyBody}</Markdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer-area footer-eleven">
        <div className="footer-top">
          <div className="container">
            <div className="inner-content">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="footer-widget f-about">
                    <div className="logo">
                      <a href="/">
                        <img
                          src="Logo-primary.webp"
                          className="img-fluid"
                          style={{ maxHeight: "50px" }}
                        />
                      </a>
                    </div>
                    <p>
                      <b>{Constants.APP_NAME}</b>
                      <br />
                      Enhancing access to a high-quality Computer Science
                      Education.
                    </p>
                    <p className="copyright-text">
                      <span>
                        ©{new Date().getFullYear()} {Constants.APP_NAME}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="footer-widget f-link">
                    <h5>Get Started</h5>
                    <ul>
                      <li>
                        <a href="/login">Login</a>
                      </li>
                      <li>
                        <a href="/signup">Signup</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="footer-widget f-link">
                    <h5>Legal</h5>
                    <ul>
                      <li>
                        <a href="/privacy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms">Terms And Conditions</a>
                      </li>
                      <li>
                        <a href="/cookies">Cookies Policy</a>
                      </li>
                      <li>
                        <a href="/disclaimer">Disclaimer</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Box>
  );
};

export default Policies;

const PrivacyMarkdown = `
# Privacy policy
The privacy of your data is a big deal to us. In this policy, we lay out: what data we collect and why; how your data is handled; and your rights with respect to your data. We promise we never sell your data: never have, never will.

This policy applies to all products built and maintained by ${Constants.APP_NAME}.

## What we collect and why
Our guiding principle is to collect only what we need. Here’s what that means in practice:

### Identity & access
When you sign up for a ${Constants.APP_NAME} product, we ask for identifying information such as your name, email address, and company name. That’s so you can personalize your new account, and we can send you product updates and other essential information. We may also send you optional surveys from time to time to help us understand how you use our products and to make improvements. With your consent, we will send you our newsletter and other updates. We sometimes also give you the option to add a profile picture that displays in our products.

We’ll never sell your personal information to third parties, and we won’t use your name or company in marketing statements without your permission either.

### Billing information
If you sign up for a paid ${Constants.APP_NAME} product, you will be asked to provide your payment information and billing address. Credit card information is submitted directly to our payment processor (Stripe) and doesn’t hit ${Constants.APP_NAME} servers. We store a record of the payment transaction for purposes of account history, invoicing, and billing support. 

### Product interactions
We store on our servers the content that you upload or receive or maintain in your ${Constants.APP_NAME} product accounts. This is so you can use our products as intended. We keep this content as long as your account is active. If you delete your account, we’ll delete the content within 60 days. 

### Geolocation data
For most of our products, we log the full IP address used to sign up a product account and retain that for use in mitigating future spammy signups. We also log all account access by full IP address for security and fraud prevention purposes, and we keep this login data for as long as your product account is active.

### Website interactions
We collect information about your browsing activity for analytics and statistical purposes such as conversion rate testing and experimenting with new product designs. This includes, for example, your browser and operating system versions, your IP address, which web pages you visited and how long they took to load, and which website referred you to us. If you have an account and are signed in, these web analytics data are tied to your IP address and user account until your account is no longer active. The web analytics we use are described further in the Advertising and Cookies section.

### Advertising and Cookies
We use persistent first-party cookies and some third-party cookies to store certain preferences, make it easier for you to use our applications, and perform A/B testing as well as support some analytics. 

A cookie is a piece of text stored by your browser. It may help remember login information and site preferences. It might also collect information such as your browser type, operating system, web pages visited, duration of visit, content viewed, and other click-stream data. You can adjust cookie retention settings and accept or block individual cookies in your browser settings, although our apps won’t work and other aspects of our service may not function properly if you turn cookies off.

### Voluntary correspondence
When you email ${Constants.APP_NAME} with a question or to ask for help, we keep that correspondence, including your email address, so that we have a history of past correspondence to reference if you reach out in the future.

We also store information you may volunteer, for example, written responses to surveys. If you agree to a customer interview, we may ask for your permission to record the conversation for future reference or use. We will only do so with your express consent.

## When we access or share your information
**To provide products or services you’ve requested**. We use some third-party subprocessors to help run our applications and provide the Services to you. We also use third-party processors for other business functions such as managing newsletter subscriptions, sending customer surveys, and providing our company storefront.

We may share your information at your direction if you integrate a third-party service into your use of our products.

No ${Constants.APP_NAME} human looks at your content except for limited purposes with your express permission, for example, if an error occurs that stops an automated process from working and requires manual intervention to fix. These are rare cases, and when they happen, we look for root cause solutions as much as possible to avoid them recurring. We may also access your data if required in order to respond to legal process (see “When required under applicable law” below).

**To help you troubleshoot or squash a software bug, with your permission.** If at any point we need to access your content to help you with a support case, we will ask for your consent before proceeding.

**To investigate, prevent, or take action regarding [restricted uses](/restrictions).** Accessing a customer’s account when investigating potential abuse is a measure of last resort. We want to protect the privacy and safety of both our customers and the people reporting issues to us, and we do our best to balance those responsibilities throughout the process. If we discover you are using our products for a restricted purpose, we will take action as necessary, including notifying appropriate authorities where warranted.

**When required under applicable law.**

${Constants.APP_NAME} LLC is a UK company and all data infrastructure are located in the UK

* Requests for user data. Our policy is to not respond to government requests for user data unless we are compelled by legal process or in limited circumstances in the event of an emergency request. However, if UK law enforcement authorities have the necessary warrant, criminal subpoena, or court order requiring us to share data, we must comply. Likewise, we will only respond to requests from government authorities outside the UK if compelled by the UK government through procedures outlined in a mutual legal assistance treaty or agreement. It is ${Constants.APP_NAME}'s policy to notify affected users before we share data unless we are legally prohibited from doing so, and except in some emergency cases. 
* If we are audited by a tax authority, we may be required to share billing-related information. If that happens, we will share only the minimum needed, such as billing addresses and tax exemption information.

Finally, if ${Constants.APP_NAME} is acquired by or merges with another company — we don’t plan on that, but if it happens — we’ll notify you well before any of your personal information is transferred or becomes subject to a different privacy policy.

## Your rights with respect to your information
At ${Constants.APP_NAME}, we strive to apply the same data rights to all customers, regardless of their location. Some of these rights include:

* **Right to Know.** You have the right to know what personal information is collected, used, shared or sold. We outline both the categories and specific bits of data we collect, as well as how they are used, in this privacy policy.
* **Right of Access.** This includes your right to access the personal information we gather about you, and your right to obtain information about the sharing, storage, security and processing of that information.
* **Right to Correction.** You have the right to request correction of your personal information.
* **Right to Erasure / “To Be Forgotten”.** This is your right to request, subject to certain limitations under applicable law, that your personal information be erased from our possession and, by extension, from all of our service providers. Fulfillment of some data deletion requests may prevent you from using 37signals services because our applications may then no longer work. In such cases, a data deletion request may result in closing your account.
* **Right to Complain.** You have the right to make a complaint regarding our handling of your personal information with the appropriate supervisory authority.
* **Right to Restrict Processing.** This is your right to request restriction of how and why your personal information is used or processed, including opting out of sale of personal information. (Again: we never have and never will sell your personal data.)
* **Right to Object.** You have the right, in certain situations, to object to how or why your personal information is processed.
* **Right to Portability.** You have the right to receive the personal information we have about you and the right to transmit it to another party.  If you want to export data from your accounts, please contact support.
* **Right to not Be Subject to Automated Decision-Making.** You have the right to object to and prevent any decision that could have a legal or similarly significant effect on you from being made solely based on automated processes. This right is limited if the decision is necessary for performance of any contract between you and us, is allowed by applicable law, or is based on your explicit consent.
* **Right to Non-Discrimination.** We do not and will not charge you a different amount to use our products, offer you different discounts, or give you a lower level of customer service because you have exercised your data privacy rights. However, the exercise of certain rights may, by virtue of your exercising those rights, prevent you from using our Services.

Many of these rights can be exercised by signing in and updating your account information.

If you are in the EU or UK, you can contact your data protection authority to file a complaint or learn more about local privacy laws.

## How we secure your data

All data is encrypted via [SSL/TLS](https://en.wikipedia.org/wiki/Transport_Layer_Security) when transmitted from our servers to your browser.

## What happens when you delete content in your product accounts
In many of our applications, we give you the option to trash content. Anything you trash in your product accounts while they are active will be kept in an accessible trash can for about 25 days (it varies a little by product). After that time, the trashed content cannot be accessed via the application and we are not able to retrieve it for you. The trashed content may remain on our active servers for another 30 days, and copies of the content may be held in backups of our application databases for up to another 30 days after that. Altogether, any content trashed in your product accounts should be purged from all of our systems and logs within 90 days.

If you choose to cancel your account, your content will become immediately inaccessible and should be purged from our systems in full within 60 days. This applies both for cases when an account owner directly cancels and for auto-canceled accounts. Please refer to our [Cancellation policy](/cancellation) for more details.

## Location of site and data
Our products and other web properties are operated in the UK. If you are located in outside of the UK, **please be aware that any information you provide to us will be transferred to and stored in the UK**. By using our websites or Services and/or providing us with your personal information, you consent to this transfer.

## When transferring personal data from the EU
The European Data Protection Board (EDPB) has issued guidance that personal data transferred out of the EU must be treated with the same level of protection that is granted under EU privacy law. UK law provides similar safeguards for UK user data that is transferred out of the UK. Accordingly, ${Constants.APP_NAME} has adopted a data processing addendum with Standard Contractual Clauses to help ensure this protection. ${Constants.APP_NAME}'s DPA is available [here](/gdpr).

## Changes & questions

We may update this policy as needed to comply with relevant regulations and reflect any new practices.

Have any questions, comments, or concerns about this privacy policy, your data, or your rights with respect to your information? Please get in touch by emailing us at [${Constants.SUPPORT_EMAIL}](mailto:${Constants.SUPPORT_EMAIL}) and we’ll be happy to try to answer them!

Adapted from the [Basecamp open-source policies / CC BY 4.0](https://github.com/basecamp/policies)
`;

const CookiesPolicy = `
# Cookies Policy

Last updated: November 21, 2022

This Cookies Policy explains what Cookies are and how We use them. You should read this policy so You can understand what type of cookies We use, or the information We collect using Cookies and how that information is used.

Cookies do not typically contain any information that personally identifies a user, but personal information that we store about You may be linked to the information stored in and obtained from Cookies. For further information on how We use, store and keep your personal data secure, see our Privacy Policy.

We do not store sensitive personal information, such as mailing addresses, account passwords, etc. in the Cookies We use.

# Interpretation and Definitions

## Interpretation

The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.

## Definitions

For the purposes of this Cookies Policy:

*   **Company** (referred to as either "the Company", "We", "Us" or "Our" in this Cookies Policy) refers to ${Constants.APP_NAME}.
*   **Cookies** means small files that are placed on Your computer, mobile device or any other device by a website, containing details of your browsing history on that website among its many uses.
*   **Website** refers to the content available from ${Constants.APP_NAME}
*   **You** means the individual accessing or using the Website, or a company, or any legal entity on behalf of which such individual is accessing or using the Website, as applicable.

# The use of the Cookies

## Type of Cookies We Use

Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.

We use both session and persistent Cookies for the purposes set out below:

*   **Necessary / Essential Cookies**

    Type: Session Cookies

    Administered by: Us

    Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.

*   **Functionality Cookies**

    Type: Persistent Cookies

    Administered by: Us

    Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.

## Your Choices Regarding Cookies

If You prefer to avoid the use of Cookies on the Website, first You must disable the use of Cookies in your browser and then delete the Cookies saved in your browser associated with this website. You may use this option for preventing the use of Cookies at any time.

If You do not accept Our Cookies, You may experience some inconvenience in your use of the Website and some features may not function properly.

If You'd like to delete Cookies or instruct your web browser to delete or refuse Cookies, please visit the help pages of your web browser.

*   For the Chrome web browser, please visit this page from Google:{" "} [https://support.google.com/accounts/answer/32050](https://support.google.com/accounts/answer/32050)

*   For the Internet Explorer web browser, please visit this page from Microsoft:{" "} [http://support.microsoft.com/kb/278835](http://support.microsoft.com/kb/278835)

*   For the Firefox web browser, please visit this page from Mozilla:{" "} [https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored](https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored)

*   For the Safari web browser, please visit this page from Apple:{" "} [https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac](https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac)

For any other web browser, please visit your web browser's official web pages.

## More Information about Cookies

You can learn more about cookies here:{" "} [All About Cookies](https://www.termsfeed.com/blog/cookies/) .

## Contact Us

If you have any questions about this Cookies Policy, You can contact us:

*   By email: ${Constants.SUPPORT_EMAIL}`;

const TermsPolicy = `
# Terms of Service
When we say “Company”, “we”, “our”, or “us” in this document, we are referring to ${Constants.APP_NAME}.

When we say “Services”, we mean any product created and maintained by ${Constants.APP_NAME}.

When we say “You” or “your”, we are referring to the people or organizations that own an account with one or more of our Services.

We may update these Terms of Service in the future.

When you use our Services, now or in the future, you are agreeing to the latest Terms of Service. That’s true for any of our existing and future products and all features that we add to our Services over time. There may be times where we do not exercise or enforce any right or provision of the Terms of Service; in doing so, we are not waiving that right or provision. **These terms do contain a limitation of our liability.**

If you violate any of the terms, we may terminate your account.

## Account Terms
1. You are responsible for maintaining the security of your account and password. The Company cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.
2. You may not use the Services for any purpose outlined in our [Use Restrictions policy](/restrictions).
3. You are responsible for all content posted and activity that occurs under your account. That includes content posted by others who either: (a) have access to your login credentials; or (b) have their own logins under your account.
4. You must be a human. Accounts registered by “bots” or other automated methods are not permitted.

## Payment, Refunds, and Plan Changes
1. If you are using a free version of one of our Services, it is really free: we do not ask you for your credit card and — just like for customers who pay for our Services — we do not sell your data.
2. For paid Services that offer a free trial, we explain the length of trial when you sign up. After the trial period, you need to pay in advance to keep using the Service. If you do not pay, we will freeze your account and it will be inaccessible until you make payment. If your account has been frozen for a while, we will queue it up for auto-cancellation. See our [Cancellation policy](/cancellationPolicy) for more details.
3. If you are upgrading from a free plan to a paid plan, we will charge your card immediately and your billing cycle starts on the day of upgrade. For other upgrades or downgrades in plan level, the new rate starts from the next billing cycle.
4. We process refunds according to our [Fair Refund policy](/refunds).

## Cancellation and Termination
1. You are solely responsible for properly cancelling your account. Within each of our Services, we provide a simple no-questions-asked cancellation link. You can find instructions for how to cancel your account in our [Cancellation policy](/cancellationPolicy). An email or phone request to cancel your account is not automatically considered cancellation. If you need help cancelling your account, you can always [contact our Support team](mailto:${Constants.SUPPORT_EMAIL}).
2. All of your content will be inaccessible from the Services immediately upon account cancellation. Within 30 days, all content will be permanently deleted from active systems and logs. Within 60 days, all content will be permanently deleted from our backups. We cannot recover this information once it has been permanently deleted.
3. If you cancel the Service before the end of your current paid up month, your cancellation will take effect immediately, and you will not be charged again. We do not automatically prorate unused time in the last billing cycle. See our [Fair Refund policy](/refunds) for more details.
4. We have the right to suspend or terminate your account and refuse any and all current or future use of our Services for any reason at any time. Suspension means you and any other users on your account will not be able to access the account or any content in the account. Termination will furthermore result in the deletion of your account or your access to your account, and the forfeiture and relinquishment of all content in your account. We also reserve the right to refuse the use of the Services to anyone for any reason at any time. We have this clause because statistically speaking, out of the hundreds of thousands of accounts on our Services, there is at least one doing something nefarious. There are some things we staunchly stand against and this clause is how we exercise that stance. For more details, see our [Use Restrictions policy](/restrictions).
5. Verbal, physical, written or other abuse (including threats of abuse or retribution) of Company employee or officer will result in immediate account termination.

## Modifications to the Service and Prices
1. We make a promise to our customers to support our Services. We reserve the right at any time to modify or discontinue, temporarily or permanently, any part of our Services with or without notice.
2. Sometimes we change the pricing structure for our products. When we do that, we tend to exempt existing customers from those changes. However, we may choose to change the prices for existing customers. If we do so, we will give at least 30 days notice and will notify you via the email address on record. We may also post a notice about changes on our websites or the affected Services themselves.

## Uptime, Security, and Privacy
1. Your use of the Services is at your sole risk. We provide these Services on an “as is” and “as available” basis. We do not offer service-level agreements for most of our Services but do take uptime of our applications seriously.
2. We reserve the right to temporarily disable your account if your usage significantly exceeds the average usage of other customers of the Services. Of course, we’ll reach out to the account owner before taking any action except in rare cases where the level of use may negatively impact the performance of the Service for other customers.
3. We take many measures to protect and secure your data through backups, redundancies, and encryption. We enforce encryption for data transmission from the public Internet. There are some edge cases where we may send your data through our network unencrypted.
4. When you use our Services, you entrust us with your data. We take that trust to heart. You agree that ${Constants.APP_NAME} may process your data as described in our [Privacy Policy](/privacy) and for no other purpose. We as humans can access your data for the following reasons:
  - **To help you with support requests you make.**
  - **On the rare occasions when an error occurs that stops an automated process partway through.** We get automated alerts when such errors occur. When we can fix the issue and restart automated processing without looking at any personal data, we do. In rare cases, we have to look at a minimum amount of personal data to fix the issue. In these rare cases, we aim to fix the root cause as much as possible to avoid the errors from reoccurring.
  - **To safeguard ${Constants.APP_NAME}.** We’ll look at logs and metadata as part of our work to ensure the security of your data and the Services as a whole.
  - **To the extent required by applicable law.** As a UK company with all data infrastructure located in the UK, we only preserve or share customer data if compelled by a UK government authority with a legally binding order or proper request under the Stored Communications Act. If a non-UK authority approaches ${Constants.APP_NAME} for assistance, our default stance is to refuse unless the order has been approved by the UK government, which compels us to comply through procedures outlined in an established mutual legal assistance treaty or agreement mechanism. If ${Constants.APP_NAME} is audited by a tax authority, we only share the bare minimum billing information needed to complete the audit.

5. We use third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Services.
6. Under the California Consumer Privacy Act (“CCPA”), ${Constants.APP_NAME} is a “service provider”, not a “business” or “third party”, with respect to your use of the Services. That means we process any data you share with us only for the purpose you signed up for and as described in these Terms of Service and [Privacy policy](/privacy). We do not retain, use, disclose, or sell any of that information for any other commercial purposes unless we have your explicit permission. And on the flip-side, you agree to comply with your requirements under the CCPA and not use ${Constants.APP_NAME}'s Services in a way that violates the regulations.

## Copyright and Content Ownership
1. All content posted on the Services must comply with UK copyright law.
2. We claim no intellectual property rights over the material you provide to the Services. All materials uploaded remain yours.
3. We do not pre-screen content, but reserve the right (but not the obligation) in our sole discretion to refuse or remove any content that is available via the Service.
4. The names, look, and feel of the Services are copyright© to the Company. All rights reserved. You may not duplicate, copy, or reuse any portion of the HTML, CSS, JavaScript, or visual design elements without express written permission from the Company. You must request permission to use the Company’s logo or any Service logos for promotional purposes. Please [email us](mailto:${Constants.SUPPORT_EMAIL}) requests to use logos. We reserve the right to rescind this permission if you violate these Terms of Service.
5. You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Services, use of the Services, or access to the Services without the express written permission by the Company.
6. You must not modify another website so as to falsely imply that it is associated with the Services or the Company.

## Features and Bugs
We design our Services with care, based on our own experience and the experiences of customers who share their time and feedback. However, there is no such thing as a service that pleases everybody. We make no guarantees that our Services will meet your specific requirements or expectations.

We also test all of our features extensively before shipping them. As with any software, our Services inevitably have some bugs. We track the bugs reported to us and work through priority ones, especially any related to security or privacy. Not all reported bugs will get fixed and we don’t guarantee completely error-free Services.

## Services Adaptations and API Terms
We offer Application Program Interfaces (“API”s) for some of our Services. Any use of the API, including through a third-party product that accesses the Services, is bound by the terms of this agreement plus the following specific terms:

1. You expressly understand and agree that we are not liable for any damages or losses resulting from your use of the API or third-party products that access data via the API.
2. Third parties may not access and employ the API if the functionality is part of an application that remotely records, monitors, or reports a Service user’s activity *other than time tracking*, both inside and outside the applications. The Company, in its sole discretion, will determine if an integration service violates this bylaw. A third party that has built and deployed an integration for the purpose of remote user surveillance will be required to remove that integration.
3. Abuse or excessively frequent requests to the Services via the API may result in the temporary or permanent suspension of your account’s access to the API. The Company, in its sole discretion, will determine abuse or excessive usage of the API. If we need to suspend your account’s access, we will attempt to warn the account owner first. If your API usage could or has caused downtime, we may cut off access without prior notice.

## Liability
We mention liability throughout these Terms but to put it all in one section:

***You expressly understand and agree that the Company shall not be liable, in law or in equity, to you or to any third party for any direct, indirect, incidental, lost profits, special, consequential, punitive or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if the Company has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the Services; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the Services; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the service; (v) or any other matter relating to this Terms of Service or the Services, whether as a breach of contract, tort (including negligence whether active or passive), or any other theory of liability.***

In other words: choosing to use our Services does mean you are making a bet on us. If the bet does not work out, that’s on you, not us. We do our darnedest to be as safe a bet as possible through careful management of the business; investments in security, infrastructure, and talent. If you choose to use our Services, thank you for betting on us.

If you have a question about any of the Terms of Service, please [contact our Support team](mailto:${Constants.SUPPORT_EMAIL}).

Adapted from the [Basecamp open-source policies / CC BY 4.0](https://github.com/basecamp/policies)`;

const Disclaimer = `
# GDPR POLICY
The EU General Data Protection Regulation (GDPR) went into effect on May 25, 2018. ${Constants.APP_NAME} is ready.

## Does GDPR affect me?
If you're based in the EU or do business in the EU, yeah! GDPR has a long reach. If you have any EU personal data in your ${Constants.APP_NAME} account, such as names, email addresses, ID numbers, or… anything personally identifiable, then GDPR applies. You are a Controller of personal data under GDPR, so you need to enter into GDPR-compliant data processing agreements with any online services and third party vendors you rely on, including ${Constants.APP_NAME}. These agreements are commonly called a Data Processing Addendum, or DPA.

## Data Processing Addendum
Contracts required! Processing EU personal data must be governed by a GDPR-compliant contract. We provide a standard Data Processing Addendum (DPA) to extend GDPR privacy principles, rights, and obligations everywhere personal data is processed. 

## Subprocessors
${Constants.APP_NAME} uses third party subprocessors, such as cloud computing providers and customer support software, to provide our services. We enter into GDPR-compliant data processing agreements with each subprocessor, and require the same of them.

Adapted from the [Basecamp open-source policies / CC BY 4.0](https://github.com/basecamp/policies)

# Copyright Infringement Claims

## Notification of Copyright Infringement Claims
Making original work is hard! As described in our [Use Restrictions policy](/restrictions), you can’t use ${Constants.APP_NAME}* to make or disseminate work that uses the intellectual property of others beyond the bounds of [fair use](https://www.copyright.gov/fair-use/more-info.html).

Are you a copyright owner? Under the Digital Millennium Copyright Act (17 U.S.C. § 512), you have the right to notify us (${Constants.APP_NAME}) if you believe that an account user of any product we built and maintain has infringed on your work(s) as copyright owner. To be effective, the notification of claimed infringement must be written. Please include the following information:
- A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
- Identification of the copyrighted work(s) claimed to have been infringed. If there are multiple, please share a representative list of those works.
- A way for us to locate the material you believe is infringing the copyrighted work.
- Your name and contact information so that we can get back to you. Email address is preferred but a telephone number or mailing address works too.
- A statement that you, in good faith, believe that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.
- A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.

## Digital Millennium Copyright Act (“DCMA”) Counter-notifications
On the flip-side, if you believe your material has been removed in error, you can file a written counter-notification. Please include the following information:
- A physical or electronic signature, or the signature of the person authorized to act on your behalf.
- A description of the material that was removed.
- A description of where the material appeared in Basecamp products prior to their removal.
- Your name and contact information so that we can get back to you. Email address is preferred but a telephone number or mailing address works too.
- A statement under penalty of perjury that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification.
- A statement that you consent to the jurisdiction of the Federal District Court for the judicial district in which your address is located, or if your address is outside of the United States, in the Northern District of Illinois (where Basecamp is located).
- A statement that you will accept service of process from the person who filed the original DMCA notice or an agent of that person. (In other words, you’ve designated that person to receive documents on your behalf.)

## Where to Send Notices

You can notify us of either copyright infringement claims or DCMA counter-notifications through our email channel:
**By email**: [${Constants.SUPPORT_EMAIL}](mailto:${Constants.SUPPORT_EMAIL})


**This policy and process applies to any product created and owned by Basecamp, LLC. That includes Basecamp (any version), HEY, Highrise, Campfire, Backpack, Writeboard, and Ta-da List.*
Adapted from the [Basecamp open-source policies / CC BY 4.0](https://github.com/basecamp/policies)

# Cancellation policy
*Last updated: November 22, 2022*

We want satisfied customers, not hostages. That’s why we make it easy for you to cancel your account directly in all of our apps — no phone calls required, no questions asked.

Account owners can cancel their subscription in app by
1. Logging into to the application.
2. Clicking Manage Subscription
3. Cancelling the subscription

Our legal responsibility is to account owners, which means we cannot cancel an account at the request of anyone else. If for whatever reason you no longer know who the account owner is, [contact us](mailto:${Constants.SUPPORT_EMAIL}). We will gladly reach out to any current account owners at the email addresses we have on file.

## What happens when you cancel an account?
You won’t be able to access any of the features of the app after you cancel.

We’ll permanently delete the content in your account from our servers 30 days after account deletion, and from our backups within 60 days. Retrieving content for a single account from a backup isn’t possible, so if you change your mind you’ll need to do it within the first 30 days after cancellation. **Content can’t be recovered once it has been permanently deleted.**

We won’t bill you again once you cancel. We don’t automatically prorate any unused time you may have left.

## ${Constants.APP_NAME} Initiated Cancellations
We may cancel accounts if they have been inactive for an extended period:

We also retain the right to suspend or terminate accounts for any reason at any time, as outlined in our [Terms of Service](/terms). In practice, this generally means we will cancel your account without notice if we have evidence that you are using our products to engage in abusive behaviour.

Adapted from the [Basecamp open-source policies / CC BY 4.0](https://github.com/basecamp/policies)

# A fair refund policy.
Bad refund policies are infuriating. You feel like the company is just trying to rip you off. We never want our customers to feel that way, so our refund policy is simple: If you’re ever unhappy with our products* for any reason, just contact [${Constants.SUPPORT_EMAIL}](mailto:${Constants.SUPPORT_EMAIL}) and we'll take care of you.

## Examples of full refunds we’d grant.
* If you were just charged for your next month of service but you meant to cancel, we’re happy to refund that extra charge.
* If you tried one of our products for less than a month and you weren’t happy with it, you can have your money back.

## Examples of partial refunds or credits we’d grant.
* If you forgot to cancel your account a year ago, we’ll review your account usage and figure out a partial refund based on how many months you used it.
* If we had extended downtime (multiple hours in a day, or multiple days in a month) or you emailed customer service and it took multiple days to get back to you, we’d issue a partial credit to your account.

## Get in touch
At the end of the day, nearly everything on the edges comes down to a case-by-case basis. [Send us a note](mailto:${Constants.SUPPORT_EMAIL}), tell us what's up, and we'll work with you to make sure you’re happy.

Adapted from the [Basecamp open-source policies / CC BY 4.0](https://github.com/basecamp/policies)`;
