import React from "react";
import { useTheme } from "@mui/material/styles";
import { Course as CourseType } from "../../../Api/Types/Course";
import TitleCard from "./TitleCard";
import PastPapersCard from "./PastPapersCard";

interface Props {
  course: CourseType;
  courseId: string | undefined;
}

const PastPaperContainer = ({ course }: Props) => {
  const theme = useTheme();

  return (
    <>
      <TitleCard course={course} theme={theme} />

      <PastPapersCard theme={theme} course={course} />
    </>
  );
};

export default PastPaperContainer;
