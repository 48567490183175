import React, { useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import { TextField, Typography, useTheme } from "@mui/material";
import Card from "../../Components/Card/Card";
import { SetSnackbar } from "../../Context/SnackbarContext";
import { LoadingButton } from "@mui/lab";
import { contactUs } from "../../Api/Endpoints/userCalls";
import { isMobile } from "react-device-detect";

export default function Contact() {
  const theme = useTheme();
  const setSnackbar = useContext(SetSnackbar);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  const isMessageInvalid = (message: string): boolean => message.length < 10;

  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      paddingX={{ xs: 0, x: 0, s: 4, m: 7, l: 8, xl: 10 }}
    >
      <Card title="Contact Us">
        <>
          <Typography>
            Need help with your account or want to give us some feedback? Enter
            your request below to let us know your request and we&apos;ll aim to
            get back to you within 5 working days.
          </Typography>

          {messageSent ? (
            <Typography
              color="success"
              sx={{ color: theme.palette.success.main, my: 5 }}
              fontWeight="400"
            >
              Your message has been sent. We&apos;ll aim to get back to you
              within 5 working days.
            </Typography>
          ) : (
            <>
              <TextField
                sx={{ my: 3 }}
                label="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onBlur={(e) =>
                  setMessageError(isMessageInvalid(e.target.value))
                }
                fullWidth
                error={messageError}
                helperText={
                  messageError
                    ? "Please enter at least 10 characters"
                    : undefined
                }
                multiline
                minRows={6}
                maxRows={10}
              />

              <LoadingButton
                loading={loading}
                variant="outlined"
                onClick={() => {
                  if (isMessageInvalid(message)) {
                    setMessageError(true);
                    return;
                  }
                  setMessageError(false);
                  setLoading(true);
                  contactUs(message)
                    .then(() => setMessageSent(true))
                    .catch(() =>
                      setSnackbar({
                        status: "error",
                        message:
                          "An error occurred sending your message. Please try again",
                      })
                    )
                    .finally(() => setLoading(false));
                }}
                sx={{ width: isMobile ? "100%" : "200px", mt: 3 }}
              >
                Send
              </LoadingButton>
            </>
          )}
        </>
      </Card>
    </Grid>
  );
}
