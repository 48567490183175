import { Course } from "../Types/Course";
import { Lesson } from "../Types/Lesson";
import { QuestionsAnswered, UserLessonProgress } from "../Types/UserProgress";
import { fetchBase } from "./fetchBase";

export const getCourse = async (courseId: string) =>
  fetchBase<Course>(`lessons/getCourse?courseId=${courseId}`, "GET");

export const getLesson = async (lessonId: string, courseId: string) =>
  fetchBase<Lesson>(
    `lessons/getLesson?lessonId=${lessonId}&courseId=${courseId}`,
    "GET"
  );

export const getCourseAnswers = async (courseId: string) =>
  fetchBase<UserLessonProgress>(
    `lessons/getCourseAnswers?courseId=${courseId}`,
    "GET"
  );

export const saveLessonAnswers = async (
  lessonId: string,
  courseId: string,
  answers: QuestionsAnswered
) =>
  fetchBase<null>(
    `lessons/saveLessonAnswers?lessonId=${lessonId}&courseId=${courseId}&answers=${JSON.stringify(
      answers
    )}`,
    "POST"
  );
