import React from "react";

import { LinearProgress, useTheme } from "@mui/material";
import {
  QuestionAnswerState,
  QuestionsAnswered,
} from "../../Api/Types/UserProgress";

interface Props {
  questionsAnswered: QuestionsAnswered;
}

const CalculateQuestionCounts = (
  questionsAnswered: QuestionsAnswered
): {
  numberCorrect: number;
  numberIncomplete: number;
  numberIncorrect: number;
} => {
  let numberCorrect = 0;
  let numberIncomplete = 0;
  let numberIncorrect = 0;

  if (Object.keys(questionsAnswered).length === 0) {
    return { numberCorrect: 0, numberIncomplete: 1, numberIncorrect: 0 };
  }

  Object.values(questionsAnswered).forEach((x) => {
    switch (x) {
      case QuestionAnswerState.Unanswered:
        numberIncomplete += 1;
        break;
      case QuestionAnswerState.Correct:
        numberCorrect += 1;
        break;
      case QuestionAnswerState.Incorrect:
        numberIncorrect += 1;
        break;
    }
  });

  return { numberCorrect, numberIncomplete, numberIncorrect };
};

export const CalculatePercentageScore = (
  questionsAnswered: QuestionsAnswered
): string => {
  const { numberCorrect, numberIncomplete, numberIncorrect } =
    CalculateQuestionCounts(questionsAnswered);

  if (numberCorrect === 0 && numberIncorrect === 0) {
    return "Questions Not Attempted Yet";
  }

  const percentage =
    numberCorrect / (numberIncomplete + numberIncorrect + numberCorrect);

  return `${Math.round(percentage * 100)}% correct on last test`;
};

const LessonProgress = ({ questionsAnswered }: Props): JSX.Element => {
  const theme = useTheme();

  const { numberCorrect, numberIncomplete, numberIncorrect } =
    CalculateQuestionCounts(questionsAnswered);

  const totalQuestions = numberCorrect + numberIncomplete + numberIncorrect;
  const correctPercent = (numberCorrect / totalQuestions) * 100;
  const incorrectPercent =
    ((numberCorrect + numberIncorrect) / totalQuestions) * 100; // Needs the height of correct + incorrect otherwise it's hidden by the correct bar

  return (
    <LinearProgress
      variant="buffer"
      value={correctPercent}
      valueBuffer={incorrectPercent}
      sx={{
        borderRadius: 5,
        height: 7,
        "& .MuiLinearProgress-dashed": {
          backgroundColor: theme.palette.grey[300],
          backgroundImage: "none",
          animation: "none",
        },
        "& .MuiLinearProgress-bar1Buffer": {
          backgroundColor: theme.palette.success.main,
        },
        "& .MuiLinearProgress-bar2Buffer": {
          backgroundColor: theme.palette.error.main,
        },
      }}
    />
  );
};

export default LessonProgress;
